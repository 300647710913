export const data = [
    {
        "age": 5,
        "gender": 2,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Corticosteroids",
                "day": 4,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 3,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 2,
                "therapy_index": 3
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Diuretics",
                "day": 1,
                "therapy_index": 9
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 3,
                "therapy_index": 2
            },
            {
                "therapy": "Corticosteroids",
                "day": 3,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Azithromycin",
                "day": 1,
                "therapy_index": 13
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Prone positioning",
                "day": 2,
                "therapy_index": 4
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 3,
                "therapy_index": 8
            },
            {
                "therapy": "Corticosteroids",
                "day": 9,
                "therapy_index": 10
            },
            {
                "therapy": "Tocilizumab",
                "day": 9,
                "therapy_index": 15
            }
        ]
    },
    {
        "age": 3,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Azithromycin",
                "day": 2,
                "therapy_index": 13
            },
            {
                "therapy": "Hydroxychloroquine",
                "day": 2,
                "therapy_index": 14
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 6,
                "therapy_index": 2
            },
            {
                "therapy": "Tracheostomy",
                "day": 14,
                "therapy_index": 1
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 3,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 5,
                "therapy_index": 8
            }
        ]
    },
    {
        "age": 4,
        "gender": 1,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Corticosteroids",
                "day": 8,
                "therapy_index": 10
            },
            {
                "therapy": "Tracheostomy",
                "day": 9,
                "therapy_index": 1
            },
            {
                "therapy": "Diuretics",
                "day": 10,
                "therapy_index": 9
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 3,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 4,
                "therapy_index": 3
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 5,
                "therapy_index": 2
            },
            {
                "therapy": "Diuretics",
                "day": 8,
                "therapy_index": 9
            },
            {
                "therapy": "Corticosteroids",
                "day": 20,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Diuretics",
                "day": 3,
                "therapy_index": 9
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 8,
                "therapy_index": 3
            }
        ]
    },
    {
        "age": 2,
        "gender": 2,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            }
        ]
    },
    {
        "age": 6,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 2,
                "therapy_index": 2
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            }
        ]
    },
    {
        "age": 2,
        "gender": 2,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 7,
                "therapy_index": 3
            },
            {
                "therapy": "Diuretics",
                "day": 7,
                "therapy_index": 9
            },
            {
                "therapy": "Tracheostomy",
                "day": 15,
                "therapy_index": 1
            }
        ]
    },
    {
        "age": 5,
        "gender": 1,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 3,
                "therapy_index": 2
            }
        ]
    },
    {
        "age": 3,
        "gender": 1,
        "ARDS": 1,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Diuretics",
                "day": 1,
                "therapy_index": 9
            },
            {
                "therapy": "Hydroxychloroquine",
                "day": 2,
                "therapy_index": 14
            },
            {
                "therapy": "Corticosteroids",
                "day": 3,
                "therapy_index": 10
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 7,
                "therapy_index": 8
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 8,
                "therapy_index": 3
            }
        ]
    },
    {
        "age": 5,
        "gender": 1,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Tracheostomy",
                "day": 2,
                "therapy_index": 1
            }
        ]
    },
    {
        "age": 6,
        "gender": 1,
        "ARDS": 3,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 3,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 2,
                "therapy_index": 3
            },
            {
                "therapy": "Diuretics",
                "day": 3,
                "therapy_index": 9
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 9,
                "therapy_index": 8
            }
        ]
    },
    {
        "age": 3,
        "gender": 2,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Diuretics",
                "day": 3,
                "therapy_index": 9
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 5,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 8,
                "therapy_index": 3
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 9,
                "therapy_index": 8
            },
            {
                "therapy": "Prone positioning",
                "day": 10,
                "therapy_index": 4
            },
            {
                "therapy": "Corticosteroids",
                "day": 10,
                "therapy_index": 10
            },
            {
                "therapy": "Tracheostomy",
                "day": 15,
                "therapy_index": 1
            },
            {
                "therapy": "Therapeutic heparin",
                "day": 20,
                "therapy_index": 11
            },
            {
                "therapy": "Inhaled prostacyclin",
                "day": 28,
                "therapy_index": 6
            }
        ]
    },
    {
        "age": 6,
        "gender": 2,
        "ARDS": 1,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 3,
                "therapy_index": 2
            }
        ]
    },
    {
        "age": 3,
        "gender": 2,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Tracheostomy",
                "day": 10,
                "therapy_index": 1
            }
        ]
    },
    {
        "age": 1,
        "gender": 2,
        "ARDS": 3,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Corticosteroids",
                "day": 2,
                "therapy_index": 10
            },
            {
                "therapy": "Diuretics",
                "day": 6,
                "therapy_index": 9
            },
            {
                "therapy": "Remdesivir",
                "day": 15,
                "therapy_index": 12
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 16,
                "therapy_index": 3
            },
            {
                "therapy": "Inhaled nitric oxide",
                "day": 16,
                "therapy_index": 5
            },
            {
                "therapy": "Prone positioning",
                "day": 17,
                "therapy_index": 4
            },
            {
                "therapy": "Therapeutic heparin",
                "day": 17,
                "therapy_index": 11
            },
            {
                "therapy": "Anankinra",
                "day": 17,
                "therapy_index": 16
            }
        ]
    },
    {
        "age": 6,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 2,
                "therapy_index": 17
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 3,
                "therapy_index": 2
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 3,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Prone positioning",
                "day": 3,
                "therapy_index": 4
            },
            {
                "therapy": "Tracheostomy",
                "day": 4,
                "therapy_index": 1
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Diuretics",
                "day": 3,
                "therapy_index": 9
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 4,
                "therapy_index": 2
            },
            {
                "therapy": "Corticosteroids",
                "day": 10,
                "therapy_index": 10
            },
            {
                "therapy": "Tracheostomy",
                "day": 19,
                "therapy_index": 1
            },
            {
                "therapy": "Inhaled prostacyclin",
                "day": 23,
                "therapy_index": 6
            }
        ]
    },
    {
        "age": 3,
        "gender": 2,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 4,
                "therapy_index": 2
            },
            {
                "therapy": "Diuretics",
                "day": 4,
                "therapy_index": 9
            },
            {
                "therapy": "Corticosteroids",
                "day": 5,
                "therapy_index": 10
            },
            {
                "therapy": "Remdesivir",
                "day": 5,
                "therapy_index": 12
            },
            {
                "therapy": "Tracheostomy",
                "day": 20,
                "therapy_index": 1
            }
        ]
    },
    {
        "age": 6,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Azithromycin",
                "day": 2,
                "therapy_index": 13
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 2,
                "therapy_index": 17
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 12,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 14,
                "therapy_index": 3
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 3,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 1,
                "therapy_index": 8
            },
            {
                "therapy": "Corticosteroids",
                "day": 1,
                "therapy_index": 10
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 2,
                "therapy_index": 3
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 5,
                "therapy_index": 2
            },
            {
                "therapy": "Diuretics",
                "day": 6,
                "therapy_index": 9
            },
            {
                "therapy": "Tocilizumab",
                "day": 10,
                "therapy_index": 15
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 2,
                "therapy_index": 8
            },
            {
                "therapy": "Tocilizumab",
                "day": 6,
                "therapy_index": 15
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 9,
                "therapy_index": 3
            },
            {
                "therapy": "Tracheostomy",
                "day": 19,
                "therapy_index": 1
            }
        ]
    },
    {
        "age": 5,
        "gender": 1,
        "ARDS": 3,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Prone positioning",
                "day": 1,
                "therapy_index": 4
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 2,
                "therapy_index": 3
            },
            {
                "therapy": "Diuretics",
                "day": 6,
                "therapy_index": 9
            }
        ]
    },
    {
        "age": 3,
        "gender": 2,
        "ARDS": 3,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Tracheostomy",
                "day": 2,
                "therapy_index": 1
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 2,
                "therapy_index": 3
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 2,
                "therapy_index": 8
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 3,
                "therapy_index": 2
            },
            {
                "therapy": "Corticosteroids",
                "day": 8,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 3,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 2,
                "therapy_index": 3
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 2,
                "therapy_index": 8
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 3,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 1,
                "therapy_index": 8
            },
            {
                "therapy": "Corticosteroids",
                "day": 2,
                "therapy_index": 10
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 4,
                "therapy_index": 3
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 5,
                "therapy_index": 2
            },
            {
                "therapy": "Prone positioning",
                "day": 14,
                "therapy_index": 4
            },
            {
                "therapy": "Therapeutic heparin",
                "day": 26,
                "therapy_index": 11
            },
            {
                "therapy": "Inhaled nitric oxide",
                "day": 29,
                "therapy_index": 5
            }
        ]
    },
    {
        "age": 5,
        "gender": 1,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 3,
                "therapy_index": 3
            },
            {
                "therapy": "Tracheostomy",
                "day": 12,
                "therapy_index": 1
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 3,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Corticosteroids",
                "day": 2,
                "therapy_index": 10
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 4,
                "therapy_index": 2
            }
        ]
    },
    {
        "age": 5,
        "gender": 1,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Tocilizumab",
                "day": 1,
                "therapy_index": 15
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 2,
                "therapy_index": 3
            },
            {
                "therapy": "Corticosteroids",
                "day": 6,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 5,
        "gender": 1,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 1,
                "therapy_index": 4
            },
            {
                "therapy": "Corticosteroids",
                "day": 1,
                "therapy_index": 10
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            }
        ]
    },
    {
        "age": 4,
        "gender": 1,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Corticosteroids",
                "day": 1,
                "therapy_index": 10
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Prone positioning",
                "day": 2,
                "therapy_index": 4
            },
            {
                "therapy": "Diuretics",
                "day": 3,
                "therapy_index": 9
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 5,
                "therapy_index": 3
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 2,
                "therapy_index": 2
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Prone positioning",
                "day": 5,
                "therapy_index": 4
            },
            {
                "therapy": "Corticosteroids",
                "day": 15,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Diuretics",
                "day": 4,
                "therapy_index": 9
            }
        ]
    },
    {
        "age": 5,
        "gender": 1,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Corticosteroids",
                "day": 1,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Diuretics",
                "day": 3,
                "therapy_index": 9
            },
            {
                "therapy": "Tracheostomy",
                "day": 13,
                "therapy_index": 1
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 17,
                "therapy_index": 3
            }
        ]
    },
    {
        "age": 6,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 2,
                "therapy_index": 3
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Inhaled prostacyclin",
                "day": 3,
                "therapy_index": 6
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Corticosteroids",
                "day": 2,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 4,
        "gender": 1,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 1,
                "therapy_index": 4
            },
            {
                "therapy": "Diuretics",
                "day": 5,
                "therapy_index": 9
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 5,
                "therapy_index": 17
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 6,
                "therapy_index": 8
            },
            {
                "therapy": "Tracheostomy",
                "day": 17,
                "therapy_index": 1
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Diuretics",
                "day": 3,
                "therapy_index": 9
            },
            {
                "therapy": "Corticosteroids",
                "day": 6,
                "therapy_index": 10
            },
            {
                "therapy": "Anankinra",
                "day": 7,
                "therapy_index": 16
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 9,
                "therapy_index": 8
            }
        ]
    },
    {
        "age": 1,
        "gender": 1,
        "ARDS": 3,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 1,
                "therapy_index": 4
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Tracheostomy",
                "day": 7,
                "therapy_index": 1
            }
        ]
    },
    {
        "age": 6,
        "gender": 1,
        "ARDS": 3,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 2,
                "therapy_index": 8
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 3,
                "therapy_index": 3
            },
            {
                "therapy": "Corticosteroids",
                "day": 3,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 4,
        "gender": 1,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 2,
                "therapy_index": 2
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Corticosteroids",
                "day": 14,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 3,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Diuretics",
                "day": 1,
                "therapy_index": 9
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 2,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 5,
                "therapy_index": 3
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 7,
                "therapy_index": 8
            },
            {
                "therapy": "Corticosteroids",
                "day": 10,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 6,
        "gender": 1,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Tracheostomy",
                "day": 13,
                "therapy_index": 1
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 23,
                "therapy_index": 2
            },
            {
                "therapy": "Corticosteroids",
                "day": 23,
                "therapy_index": 10
            },
            {
                "therapy": "Inhaled prostacyclin",
                "day": 25,
                "therapy_index": 6
            }
        ]
    },
    {
        "age": 3,
        "gender": 2,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 4,
                "therapy_index": 8
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 8,
                "therapy_index": 3
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 9,
                "therapy_index": 2
            },
            {
                "therapy": "Corticosteroids",
                "day": 11,
                "therapy_index": 10
            },
            {
                "therapy": "Therapeutic heparin",
                "day": 14,
                "therapy_index": 11
            },
            {
                "therapy": "Diuretics",
                "day": 15,
                "therapy_index": 9
            }
        ]
    },
    {
        "age": 2,
        "gender": 1,
        "ARDS": 3,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 2,
                "therapy_index": 2
            },
            {
                "therapy": "Inhaled nitric oxide",
                "day": 2,
                "therapy_index": 5
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 3,
                "therapy_index": 3
            },
            {
                "therapy": "Diuretics",
                "day": 4,
                "therapy_index": 9
            },
            {
                "therapy": "Inhaled prostacyclin",
                "day": 5,
                "therapy_index": 6
            },
            {
                "therapy": "Corticosteroids",
                "day": 6,
                "therapy_index": 10
            },
            {
                "therapy": "Prone positioning",
                "day": 10,
                "therapy_index": 4
            },
            {
                "therapy": "Tracheostomy",
                "day": 13,
                "therapy_index": 1
            },
            {
                "therapy": "Bronchoscopy",
                "day": 37,
                "therapy_index": 7
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 3,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 2,
                "therapy_index": 17
            },
            {
                "therapy": "Diuretics",
                "day": 5,
                "therapy_index": 9
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Diuretics",
                "day": 3,
                "therapy_index": 9
            },
            {
                "therapy": "Corticosteroids",
                "day": 7,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Prone positioning",
                "day": 2,
                "therapy_index": 4
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 4,
                "therapy_index": 17
            },
            {
                "therapy": "Tracheostomy",
                "day": 16,
                "therapy_index": 1
            }
        ]
    },
    {
        "age": 6,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Corticosteroids",
                "day": 1,
                "therapy_index": 10
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Azithromycin",
                "day": 2,
                "therapy_index": 13
            },
            {
                "therapy": "Hydroxychloroquine",
                "day": 2,
                "therapy_index": 14
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 3,
                "therapy_index": 3
            },
            {
                "therapy": "Tracheostomy",
                "day": 11,
                "therapy_index": 1
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 1,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Diuretics",
                "day": 1,
                "therapy_index": 9
            },
            {
                "therapy": "Azithromycin",
                "day": 1,
                "therapy_index": 13
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 2,
                "therapy_index": 2
            },
            {
                "therapy": "Prone positioning",
                "day": 2,
                "therapy_index": 4
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 4,
                "therapy_index": 3
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Prone positioning",
                "day": 1,
                "therapy_index": 4
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 2,
                "therapy_index": 3
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 2,
                "therapy_index": 3
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 1,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Diuretics",
                "day": 1,
                "therapy_index": 9
            },
            {
                "therapy": "Azithromycin",
                "day": 1,
                "therapy_index": 13
            },
            {
                "therapy": "Hydroxychloroquine",
                "day": 1,
                "therapy_index": 14
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 3,
                "therapy_index": 8
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 10,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 11,
                "therapy_index": 4
            },
            {
                "therapy": "Inhaled nitric oxide",
                "day": 11,
                "therapy_index": 5
            },
            {
                "therapy": "Corticosteroids",
                "day": 12,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 3,
        "gender": 1,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 1,
                "therapy_index": 4
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 3,
                "therapy_index": 2
            },
            {
                "therapy": "Diuretics",
                "day": 5,
                "therapy_index": 9
            },
            {
                "therapy": "Corticosteroids",
                "day": 13,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 3,
        "gender": 2,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Diuretics",
                "day": 1,
                "therapy_index": 9
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 3,
                "therapy_index": 3
            },
            {
                "therapy": "Corticosteroids",
                "day": 6,
                "therapy_index": 10
            },
            {
                "therapy": "Tracheostomy",
                "day": 18,
                "therapy_index": 1
            },
            {
                "therapy": "Therapeutic heparin",
                "day": 26,
                "therapy_index": 11
            }
        ]
    },
    {
        "age": 2,
        "gender": 1,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Corticosteroids",
                "day": 2,
                "therapy_index": 10
            },
            {
                "therapy": "Diuretics",
                "day": 3,
                "therapy_index": 9
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 7,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 9,
                "therapy_index": 3
            },
            {
                "therapy": "Therapeutic heparin",
                "day": 9,
                "therapy_index": 11
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 10,
                "therapy_index": 8
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 1,
                "therapy_index": 8
            },
            {
                "therapy": "Diuretics",
                "day": 6,
                "therapy_index": 9
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 7,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 7,
                "therapy_index": 3
            },
            {
                "therapy": "Therapeutic heparin",
                "day": 8,
                "therapy_index": 11
            },
            {
                "therapy": "Corticosteroids",
                "day": 13,
                "therapy_index": 10
            },
            {
                "therapy": "Tracheostomy",
                "day": 17,
                "therapy_index": 1
            }
        ]
    },
    {
        "age": 4,
        "gender": 1,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 2,
                "therapy_index": 17
            },
            {
                "therapy": "Corticosteroids",
                "day": 3,
                "therapy_index": 10
            },
            {
                "therapy": "Diuretics",
                "day": 6,
                "therapy_index": 9
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 8,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 8,
                "therapy_index": 4
            },
            {
                "therapy": "Inhaled nitric oxide",
                "day": 8,
                "therapy_index": 5
            }
        ]
    },
    {
        "age": 6,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            }
        ]
    },
    {
        "age": 4,
        "gender": 1,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Azithromycin",
                "day": 1,
                "therapy_index": 13
            },
            {
                "therapy": "Hydroxychloroquine",
                "day": 1,
                "therapy_index": 14
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Prone positioning",
                "day": 4,
                "therapy_index": 4
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 6,
                "therapy_index": 8
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 7,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 7,
                "therapy_index": 3
            },
            {
                "therapy": "Inhaled nitric oxide",
                "day": 7,
                "therapy_index": 5
            },
            {
                "therapy": "Diuretics",
                "day": 7,
                "therapy_index": 9
            },
            {
                "therapy": "Prone positioning",
                "day": 8,
                "therapy_index": 4
            },
            {
                "therapy": "Inhaled prostacyclin",
                "day": 8,
                "therapy_index": 6
            },
            {
                "therapy": "Corticosteroids",
                "day": 8,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 3,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 2,
                "therapy_index": 2
            },
            {
                "therapy": "Prone positioning",
                "day": 2,
                "therapy_index": 4
            },
            {
                "therapy": "Diuretics",
                "day": 3,
                "therapy_index": 9
            },
            {
                "therapy": "Tocilizumab",
                "day": 7,
                "therapy_index": 15
            }
        ]
    },
    {
        "age": 1,
        "gender": 2,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 2,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 2,
                "therapy_index": 4
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 2,
                "therapy_index": 17
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 3,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Corticosteroids",
                "day": 1,
                "therapy_index": 10
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 2,
                "therapy_index": 3
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Prone positioning",
                "day": 5,
                "therapy_index": 4
            },
            {
                "therapy": "Tracheostomy",
                "day": 9,
                "therapy_index": 1
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 9,
                "therapy_index": 2
            }
        ]
    },
    {
        "age": 6,
        "gender": 1,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 2,
                "therapy_index": 2
            },
            {
                "therapy": "Prone positioning",
                "day": 3,
                "therapy_index": 4
            },
            {
                "therapy": "Diuretics",
                "day": 3,
                "therapy_index": 9
            },
            {
                "therapy": "Inhaled nitric oxide",
                "day": 6,
                "therapy_index": 5
            },
            {
                "therapy": "Corticosteroids",
                "day": 6,
                "therapy_index": 10
            },
            {
                "therapy": "Bronchoscopy",
                "day": 19,
                "therapy_index": 7
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 23,
                "therapy_index": 8
            },
            {
                "therapy": "Inhaled prostacyclin",
                "day": 24,
                "therapy_index": 6
            }
        ]
    },
    {
        "age": 3,
        "gender": 2,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Prone positioning",
                "day": 1,
                "therapy_index": 4
            },
            {
                "therapy": "Corticosteroids",
                "day": 2,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 6,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 2,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 2,
                "therapy_index": 3
            },
            {
                "therapy": "Diuretics",
                "day": 3,
                "therapy_index": 9
            },
            {
                "therapy": "Remdesivir",
                "day": 3,
                "therapy_index": 12
            },
            {
                "therapy": "Corticosteroids",
                "day": 39,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 6,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Diuretics",
                "day": 1,
                "therapy_index": 9
            },
            {
                "therapy": "Prone positioning",
                "day": 3,
                "therapy_index": 4
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 4,
                "therapy_index": 3
            },
            {
                "therapy": "Azithromycin",
                "day": 4,
                "therapy_index": 13
            },
            {
                "therapy": "Hydroxychloroquine",
                "day": 4,
                "therapy_index": 14
            }
        ]
    },
    {
        "age": 4,
        "gender": 1,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 1,
                "therapy_index": 8
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Prone positioning",
                "day": 2,
                "therapy_index": 4
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 1,
                "therapy_index": 4
            },
            {
                "therapy": "Diuretics",
                "day": 1,
                "therapy_index": 9
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 3,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Diuretics",
                "day": 5,
                "therapy_index": 9
            },
            {
                "therapy": "Corticosteroids",
                "day": 7,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 3,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 1,
                "therapy_index": 4
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Corticosteroids",
                "day": 6,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 2,
        "gender": 2,
        "ARDS": 3,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            }
        ]
    },
    {
        "age": 6,
        "gender": 2,
        "ARDS": 1,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 2,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 2,
                "therapy_index": 4
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Azithromycin",
                "day": 2,
                "therapy_index": 13
            },
            {
                "therapy": "Hydroxychloroquine",
                "day": 2,
                "therapy_index": 14
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 7,
                "therapy_index": 8
            }
        ]
    },
    {
        "age": 5,
        "gender": 1,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Diuretics",
                "day": 1,
                "therapy_index": 9
            },
            {
                "therapy": "Corticosteroids",
                "day": 1,
                "therapy_index": 10
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 4,
                "therapy_index": 3
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 6,
                "therapy_index": 8
            }
        ]
    },
    {
        "age": 5,
        "gender": 1,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 1,
                "therapy_index": 4
            },
            {
                "therapy": "Diuretics",
                "day": 1,
                "therapy_index": 9
            },
            {
                "therapy": "Tocilizumab",
                "day": 1,
                "therapy_index": 15
            },
            {
                "therapy": "Corticosteroids",
                "day": 2,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 2,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 2,
                "therapy_index": 4
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Azithromycin",
                "day": 2,
                "therapy_index": 13
            },
            {
                "therapy": "Hydroxychloroquine",
                "day": 2,
                "therapy_index": 14
            },
            {
                "therapy": "Corticosteroids",
                "day": 14,
                "therapy_index": 10
            },
            {
                "therapy": "Tracheostomy",
                "day": 20,
                "therapy_index": 1
            }
        ]
    },
    {
        "age": 2,
        "gender": 1,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Azithromycin",
                "day": 2,
                "therapy_index": 13
            }
        ]
    },
    {
        "age": 5,
        "gender": 1,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Prone positioning",
                "day": 1,
                "therapy_index": 4
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Diuretics",
                "day": 3,
                "therapy_index": 9
            },
            {
                "therapy": "Tracheostomy",
                "day": 12,
                "therapy_index": 1
            }
        ]
    },
    {
        "age": 2,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 2,
                "therapy_index": 2
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 4,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 4,
                "therapy_index": 4
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 4,
                "therapy_index": 8
            },
            {
                "therapy": "Corticosteroids",
                "day": 9,
                "therapy_index": 10
            },
            {
                "therapy": "Therapeutic heparin",
                "day": 10,
                "therapy_index": 11
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Therapeutic heparin",
                "day": 1,
                "therapy_index": 11
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 7,
                "therapy_index": 3
            },
            {
                "therapy": "Inhaled prostacyclin",
                "day": 7,
                "therapy_index": 6
            },
            {
                "therapy": "Corticosteroids",
                "day": 8,
                "therapy_index": 10
            },
            {
                "therapy": "Prone positioning",
                "day": 9,
                "therapy_index": 4
            },
            {
                "therapy": "Diuretics",
                "day": 9,
                "therapy_index": 9
            },
            {
                "therapy": "Tracheostomy",
                "day": 20,
                "therapy_index": 1
            }
        ]
    },
    {
        "age": 4,
        "gender": 1,
        "ARDS": 3,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            }
        ]
    },
    {
        "age": 1,
        "gender": 2,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            }
        ]
    },
    {
        "age": 2,
        "gender": 2,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Diuretics",
                "day": 3,
                "therapy_index": 9
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 9,
                "therapy_index": 2
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Diuretics",
                "day": 3,
                "therapy_index": 9
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 6,
                "therapy_index": 3
            },
            {
                "therapy": "Inhaled nitric oxide",
                "day": 7,
                "therapy_index": 5
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 8,
                "therapy_index": 8
            },
            {
                "therapy": "Corticosteroids",
                "day": 8,
                "therapy_index": 10
            },
            {
                "therapy": "Prone positioning",
                "day": 9,
                "therapy_index": 4
            },
            {
                "therapy": "Inhaled prostacyclin",
                "day": 10,
                "therapy_index": 6
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 1,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Diuretics",
                "day": 3,
                "therapy_index": 9
            },
            {
                "therapy": "Corticosteroids",
                "day": 5,
                "therapy_index": 10
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 7,
                "therapy_index": 2
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 7,
                "therapy_index": 8
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 8,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 9,
                "therapy_index": 4
            },
            {
                "therapy": "Inhaled prostacyclin",
                "day": 9,
                "therapy_index": 6
            },
            {
                "therapy": "Inhaled nitric oxide",
                "day": 10,
                "therapy_index": 5
            },
            {
                "therapy": "Therapeutic heparin",
                "day": 10,
                "therapy_index": 11
            },
            {
                "therapy": "Bronchoscopy",
                "day": 13,
                "therapy_index": 7
            }
        ]
    },
    {
        "age": 2,
        "gender": 2,
        "ARDS": 3,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Diuretics",
                "day": 1,
                "therapy_index": 9
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 3,
                "therapy_index": 2
            },
            {
                "therapy": "Corticosteroids",
                "day": 24,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 6,
        "gender": 2,
        "ARDS": 3,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Corticosteroids",
                "day": 2,
                "therapy_index": 10
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 2,
                "therapy_index": 17
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 2,
                "therapy_index": 3
            },
            {
                "therapy": "Corticosteroids",
                "day": 2,
                "therapy_index": 10
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 3,
                "therapy_index": 2
            },
            {
                "therapy": "Diuretics",
                "day": 3,
                "therapy_index": 9
            }
        ]
    },
    {
        "age": 1,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 4,
                "therapy_index": 8
            },
            {
                "therapy": "Hydroxychloroquine",
                "day": 5,
                "therapy_index": 14
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 4,
                "therapy_index": 2
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Diuretics",
                "day": 3,
                "therapy_index": 9
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 6,
                "therapy_index": 8
            }
        ]
    },
    {
        "age": 2,
        "gender": 2,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Inhaled nitric oxide",
                "day": 1,
                "therapy_index": 5
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 1,
                "therapy_index": 8
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            }
        ]
    },
    {
        "age": 6,
        "gender": 1,
        "ARDS": 1,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Prone positioning",
                "day": 6,
                "therapy_index": 4
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 7,
                "therapy_index": 3
            },
            {
                "therapy": "Corticosteroids",
                "day": 7,
                "therapy_index": 10
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 15,
                "therapy_index": 2
            },
            {
                "therapy": "Tracheostomy",
                "day": 19,
                "therapy_index": 1
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 3,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            }
        ]
    },
    {
        "age": 3,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            }
        ]
    },
    {
        "age": 1,
        "gender": 2,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 2,
                "therapy_index": 3
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 1,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Diuretics",
                "day": 1,
                "therapy_index": 9
            },
            {
                "therapy": "Corticosteroids",
                "day": 2,
                "therapy_index": 10
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 10,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 11,
                "therapy_index": 3
            },
            {
                "therapy": "Inhaled prostacyclin",
                "day": 13,
                "therapy_index": 6
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Tracheostomy",
                "day": 3,
                "therapy_index": 1
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 3,
                "therapy_index": 3
            },
            {
                "therapy": "Inhaled prostacyclin",
                "day": 3,
                "therapy_index": 6
            },
            {
                "therapy": "Prone positioning",
                "day": 4,
                "therapy_index": 4
            },
            {
                "therapy": "Diuretics",
                "day": 5,
                "therapy_index": 9
            },
            {
                "therapy": "Bronchoscopy",
                "day": 6,
                "therapy_index": 7
            },
            {
                "therapy": "Corticosteroids",
                "day": 7,
                "therapy_index": 10
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 8,
                "therapy_index": 8
            },
            {
                "therapy": "Therapeutic heparin",
                "day": 8,
                "therapy_index": 11
            }
        ]
    },
    {
        "age": 6,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Bronchoscopy",
                "day": 1,
                "therapy_index": 7
            },
            {
                "therapy": "Diuretics",
                "day": 1,
                "therapy_index": 9
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 2,
                "therapy_index": 3
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 3,
                "therapy_index": 8
            }
        ]
    },
    {
        "age": 1,
        "gender": 2,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 1,
                "therapy_index": 8
            },
            {
                "therapy": "Tracheostomy",
                "day": 13,
                "therapy_index": 1
            },
            {
                "therapy": "Diuretics",
                "day": 24,
                "therapy_index": 9
            }
        ]
    },
    {
        "age": 2,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Diuretics",
                "day": 3,
                "therapy_index": 9
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Diuretics",
                "day": 1,
                "therapy_index": 9
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Tracheostomy",
                "day": 7,
                "therapy_index": 1
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 1,
                "therapy_index": 4
            },
            {
                "therapy": "Corticosteroids",
                "day": 1,
                "therapy_index": 10
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 3,
                "therapy_index": 2
            },
            {
                "therapy": "Diuretics",
                "day": 3,
                "therapy_index": 9
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 8,
                "therapy_index": 17
            }
        ]
    },
    {
        "age": 5,
        "gender": 1,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            }
        ]
    },
    {
        "age": 6,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 3,
                "therapy_index": 2
            },
            {
                "therapy": "Diuretics",
                "day": 3,
                "therapy_index": 9
            },
            {
                "therapy": "Corticosteroids",
                "day": 5,
                "therapy_index": 10
            },
            {
                "therapy": "Prone positioning",
                "day": 6,
                "therapy_index": 4
            },
            {
                "therapy": "Inhaled prostacyclin",
                "day": 16,
                "therapy_index": 6
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 6,
                "therapy_index": 3
            },
            {
                "therapy": "Inhaled prostacyclin",
                "day": 6,
                "therapy_index": 6
            },
            {
                "therapy": "Prone positioning",
                "day": 7,
                "therapy_index": 4
            },
            {
                "therapy": "Bronchoscopy",
                "day": 9,
                "therapy_index": 7
            },
            {
                "therapy": "Corticosteroids",
                "day": 17,
                "therapy_index": 10
            },
            {
                "therapy": "Tracheostomy",
                "day": 21,
                "therapy_index": 1
            }
        ]
    },
    {
        "age": 6,
        "gender": 2,
        "ARDS": 3,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Diuretics",
                "day": 1,
                "therapy_index": 9
            },
            {
                "therapy": "Corticosteroids",
                "day": 1,
                "therapy_index": 10
            },
            {
                "therapy": "Azithromycin",
                "day": 1,
                "therapy_index": 13
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Tracheostomy",
                "day": 4,
                "therapy_index": 1
            },
            {
                "therapy": "Bronchoscopy",
                "day": 4,
                "therapy_index": 7
            },
            {
                "therapy": "Prone positioning",
                "day": 6,
                "therapy_index": 4
            }
        ]
    },
    {
        "age": 3,
        "gender": 2,
        "ARDS": 3,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Diuretics",
                "day": 3,
                "therapy_index": 9
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 4,
                "therapy_index": 3
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 7,
                "therapy_index": 2
            },
            {
                "therapy": "Inhaled nitric oxide",
                "day": 9,
                "therapy_index": 5
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 9,
                "therapy_index": 8
            },
            {
                "therapy": "Corticosteroids",
                "day": 9,
                "therapy_index": 10
            },
            {
                "therapy": "Therapeutic heparin",
                "day": 9,
                "therapy_index": 11
            },
            {
                "therapy": "Tracheostomy",
                "day": 15,
                "therapy_index": 1
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 2,
                "therapy_index": 17
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 5,
                "therapy_index": 3
            }
        ]
    },
    {
        "age": 6,
        "gender": 2,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 2,
                "therapy_index": 8
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 2,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 8,
                "therapy_index": 4
            },
            {
                "therapy": "Diuretics",
                "day": 10,
                "therapy_index": 9
            }
        ]
    },
    {
        "age": 3,
        "gender": 2,
        "ARDS": 3,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 2,
                "therapy_index": 2
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 2,
                "therapy_index": 8
            },
            {
                "therapy": "Corticosteroids",
                "day": 2,
                "therapy_index": 10
            },
            {
                "therapy": "Therapeutic heparin",
                "day": 8,
                "therapy_index": 11
            }
        ]
    },
    {
        "age": 6,
        "gender": 1,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 2,
                "therapy_index": 3
            },
            {
                "therapy": "Corticosteroids",
                "day": 3,
                "therapy_index": 10
            },
            {
                "therapy": "Prone positioning",
                "day": 4,
                "therapy_index": 4
            },
            {
                "therapy": "Diuretics",
                "day": 4,
                "therapy_index": 9
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 6,
                "therapy_index": 8
            },
            {
                "therapy": "Therapeutic heparin",
                "day": 7,
                "therapy_index": 11
            },
            {
                "therapy": "Inhaled prostacyclin",
                "day": 8,
                "therapy_index": 6
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 2,
                "therapy_index": 17
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 3,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 6,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 6,
                "therapy_index": 4
            },
            {
                "therapy": "Inhaled nitric oxide",
                "day": 10,
                "therapy_index": 5
            },
            {
                "therapy": "Tracheostomy",
                "day": 14,
                "therapy_index": 1
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 1,
                "therapy_index": 4
            },
            {
                "therapy": "Azithromycin",
                "day": 1,
                "therapy_index": 13
            },
            {
                "therapy": "Hydroxychloroquine",
                "day": 1,
                "therapy_index": 14
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Inhaled nitric oxide",
                "day": 25,
                "therapy_index": 5
            },
            {
                "therapy": "Corticosteroids",
                "day": 25,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 3,
        "gender": 2,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 4,
                "therapy_index": 2
            },
            {
                "therapy": "Diuretics",
                "day": 4,
                "therapy_index": 9
            }
        ]
    },
    {
        "age": 3,
        "gender": 1,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Corticosteroids",
                "day": 1,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 5,
        "gender": 1,
        "ARDS": 3,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Diuretics",
                "day": 1,
                "therapy_index": 9
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 2,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 3,
                "therapy_index": 4
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 3,
                "therapy_index": 8
            },
            {
                "therapy": "Corticosteroids",
                "day": 6,
                "therapy_index": 10
            },
            {
                "therapy": "Therapeutic heparin",
                "day": 15,
                "therapy_index": 11
            }
        ]
    },
    {
        "age": 5,
        "gender": 1,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 3,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 2,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 2,
                "therapy_index": 4
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 3,
                "therapy_index": 8
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Diuretics",
                "day": 3,
                "therapy_index": 9
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 3,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 7,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 7,
                "therapy_index": 4
            },
            {
                "therapy": "Inhaled nitric oxide",
                "day": 7,
                "therapy_index": 5
            },
            {
                "therapy": "Diuretics",
                "day": 7,
                "therapy_index": 9
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 11,
                "therapy_index": 8
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Prone positioning",
                "day": 1,
                "therapy_index": 4
            },
            {
                "therapy": "Diuretics",
                "day": 1,
                "therapy_index": 9
            },
            {
                "therapy": "Azithromycin",
                "day": 1,
                "therapy_index": 13
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Anti-Fungal",
                "day": 1,
                "therapy_index": 18
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 10,
                "therapy_index": 2
            },
            {
                "therapy": "Corticosteroids",
                "day": 10,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 1,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Corticosteroids",
                "day": 1,
                "therapy_index": 10
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 5,
                "therapy_index": 2
            },
            {
                "therapy": "Tocilizumab",
                "day": 28,
                "therapy_index": 15
            }
        ]
    },
    {
        "age": 6,
        "gender": 1,
        "ARDS": 3,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 1,
                "therapy_index": 4
            },
            {
                "therapy": "Diuretics",
                "day": 5,
                "therapy_index": 9
            },
            {
                "therapy": "Corticosteroids",
                "day": 8,
                "therapy_index": 10
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 9,
                "therapy_index": 8
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Tracheostomy",
                "day": 1,
                "therapy_index": 1
            },
            {
                "therapy": "Diuretics",
                "day": 4,
                "therapy_index": 9
            },
            {
                "therapy": "Corticosteroids",
                "day": 11,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 3,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Tracheostomy",
                "day": 5,
                "therapy_index": 1
            },
            {
                "therapy": "Corticosteroids",
                "day": 9,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 2,
                "therapy_index": 17
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 3,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 3,
                "therapy_index": 4
            },
            {
                "therapy": "Corticosteroids",
                "day": 5,
                "therapy_index": 10
            },
            {
                "therapy": "Tracheostomy",
                "day": 17,
                "therapy_index": 1
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 3,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Azithromycin",
                "day": 1,
                "therapy_index": 13
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 2,
                "therapy_index": 8
            },
            {
                "therapy": "Corticosteroids",
                "day": 2,
                "therapy_index": 10
            },
            {
                "therapy": "Hydroxychloroquine",
                "day": 2,
                "therapy_index": 14
            }
        ]
    },
    {
        "age": 4,
        "gender": 1,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Corticosteroids",
                "day": 7,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 5,
        "gender": 1,
        "ARDS": 1,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 2,
                "therapy_index": 17
            }
        ]
    },
    {
        "age": 6,
        "gender": 2,
        "ARDS": 3,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Tracheostomy",
                "day": 4,
                "therapy_index": 1
            },
            {
                "therapy": "Diuretics",
                "day": 5,
                "therapy_index": 9
            },
            {
                "therapy": "Anti-Fungal",
                "day": 14,
                "therapy_index": 18
            }
        ]
    },
    {
        "age": 5,
        "gender": 1,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Diuretics",
                "day": 4,
                "therapy_index": 9
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 5,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 5,
                "therapy_index": 4
            },
            {
                "therapy": "Corticosteroids",
                "day": 9,
                "therapy_index": 10
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 10,
                "therapy_index": 2
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 10,
                "therapy_index": 8
            },
            {
                "therapy": "Inhaled prostacyclin",
                "day": 21,
                "therapy_index": 6
            },
            {
                "therapy": "Tracheostomy",
                "day": 22,
                "therapy_index": 1
            }
        ]
    },
    {
        "age": 2,
        "gender": 1,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 2,
                "therapy_index": 8
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Therapeutic heparin",
                "day": 10,
                "therapy_index": 11
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 15,
                "therapy_index": 3
            },
            {
                "therapy": "Inhaled nitric oxide",
                "day": 16,
                "therapy_index": 5
            },
            {
                "therapy": "Inhaled prostacyclin",
                "day": 16,
                "therapy_index": 6
            },
            {
                "therapy": "Corticosteroids",
                "day": 23,
                "therapy_index": 10
            },
            {
                "therapy": "Tracheostomy",
                "day": 32,
                "therapy_index": 1
            },
            {
                "therapy": "Bronchoscopy",
                "day": 42,
                "therapy_index": 7
            },
            {
                "therapy": "Prone positioning",
                "day": 43,
                "therapy_index": 4
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 1,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Prone positioning",
                "day": 8,
                "therapy_index": 4
            },
            {
                "therapy": "Corticosteroids",
                "day": 8,
                "therapy_index": 10
            },
            {
                "therapy": "Tracheostomy",
                "day": 13,
                "therapy_index": 1
            },
            {
                "therapy": "Diuretics",
                "day": 14,
                "therapy_index": 9
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 1,
                "therapy_index": 4
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Azithromycin",
                "day": 2,
                "therapy_index": 13
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 7,
                "therapy_index": 8
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 3,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            }
        ]
    },
    {
        "age": 6,
        "gender": 2,
        "ARDS": 1,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Corticosteroids",
                "day": 2,
                "therapy_index": 10
            },
            {
                "therapy": "Diuretics",
                "day": 4,
                "therapy_index": 9
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 1,
                "therapy_index": 4
            },
            {
                "therapy": "Diuretics",
                "day": 1,
                "therapy_index": 9
            },
            {
                "therapy": "Hydroxychloroquine",
                "day": 1,
                "therapy_index": 14
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 6,
                "therapy_index": 2
            },
            {
                "therapy": "Corticosteroids",
                "day": 8,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 6,
        "gender": 2,
        "ARDS": 3,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 2,
                "therapy_index": 3
            },
            {
                "therapy": "Tracheostomy",
                "day": 4,
                "therapy_index": 1
            },
            {
                "therapy": "Diuretics",
                "day": 4,
                "therapy_index": 9
            }
        ]
    },
    {
        "age": 3,
        "gender": 1,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Corticosteroids",
                "day": 4,
                "therapy_index": 10
            },
            {
                "therapy": "Diuretics",
                "day": 8,
                "therapy_index": 9
            },
            {
                "therapy": "Tracheostomy",
                "day": 18,
                "therapy_index": 1
            }
        ]
    },
    {
        "age": 5,
        "gender": 1,
        "ARDS": 3,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 2,
                "therapy_index": 3
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 2,
                "therapy_index": 8
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Prone positioning",
                "day": 3,
                "therapy_index": 4
            },
            {
                "therapy": "Corticosteroids",
                "day": 3,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 2,
                "therapy_index": 2
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 2,
                "therapy_index": 17
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 3,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 3,
                "therapy_index": 4
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Azithromycin",
                "day": 2,
                "therapy_index": 13
            },
            {
                "therapy": "Hydroxychloroquine",
                "day": 2,
                "therapy_index": 14
            }
        ]
    },
    {
        "age": 6,
        "gender": 1,
        "ARDS": 3,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Prone positioning",
                "day": 1,
                "therapy_index": 4
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 3,
                "therapy_index": 3
            },
            {
                "therapy": "Diuretics",
                "day": 3,
                "therapy_index": 9
            },
            {
                "therapy": "Tracheostomy",
                "day": 13,
                "therapy_index": 1
            }
        ]
    },
    {
        "age": 1,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 1,
                "therapy_index": 4
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Azithromycin",
                "day": 2,
                "therapy_index": 13
            },
            {
                "therapy": "Hydroxychloroquine",
                "day": 2,
                "therapy_index": 14
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 9,
                "therapy_index": 8
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Tracheostomy",
                "day": 1,
                "therapy_index": 1
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            }
        ]
    },
    {
        "age": 6,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Anti-Fungal",
                "day": 8,
                "therapy_index": 18
            },
            {
                "therapy": "Corticosteroids",
                "day": 16,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 6,
        "gender": 1,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 5,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 5,
                "therapy_index": 4
            },
            {
                "therapy": "Corticosteroids",
                "day": 8,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 4,
        "gender": 1,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 2,
                "therapy_index": 3
            },
            {
                "therapy": "Diuretics",
                "day": 3,
                "therapy_index": 9
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 8,
                "therapy_index": 8
            },
            {
                "therapy": "Inhaled nitric oxide",
                "day": 12,
                "therapy_index": 5
            },
            {
                "therapy": "Corticosteroids",
                "day": 12,
                "therapy_index": 10
            },
            {
                "therapy": "Therapeutic heparin",
                "day": 15,
                "therapy_index": 11
            },
            {
                "therapy": "Prone positioning",
                "day": 16,
                "therapy_index": 4
            },
            {
                "therapy": "Tracheostomy",
                "day": 22,
                "therapy_index": 1
            }
        ]
    },
    {
        "age": 4,
        "gender": 1,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Diuretics",
                "day": 1,
                "therapy_index": 9
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 3,
                "therapy_index": 8
            },
            {
                "therapy": "Corticosteroids",
                "day": 6,
                "therapy_index": 10
            },
            {
                "therapy": "Therapeutic heparin",
                "day": 14,
                "therapy_index": 11
            }
        ]
    },
    {
        "age": 6,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 12,
                "therapy_index": 3
            },
            {
                "therapy": "Corticosteroids",
                "day": 12,
                "therapy_index": 10
            },
            {
                "therapy": "Prone positioning",
                "day": 14,
                "therapy_index": 4
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 3,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 3,
                "therapy_index": 4
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 6,
                "therapy_index": 2
            },
            {
                "therapy": "Inhaled nitric oxide",
                "day": 7,
                "therapy_index": 5
            },
            {
                "therapy": "Diuretics",
                "day": 8,
                "therapy_index": 9
            },
            {
                "therapy": "Corticosteroids",
                "day": 8,
                "therapy_index": 10
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 10,
                "therapy_index": 8
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 3,
                "therapy_index": 8
            },
            {
                "therapy": "Corticosteroids",
                "day": 3,
                "therapy_index": 10
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 9,
                "therapy_index": 2
            },
            {
                "therapy": "Tracheostomy",
                "day": 13,
                "therapy_index": 1
            },
            {
                "therapy": "Therapeutic heparin",
                "day": 19,
                "therapy_index": 11
            },
            {
                "therapy": "Diuretics",
                "day": 22,
                "therapy_index": 9
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 3,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 3,
                "therapy_index": 4
            },
            {
                "therapy": "Diuretics",
                "day": 3,
                "therapy_index": 9
            },
            {
                "therapy": "Inhaled prostacyclin",
                "day": 4,
                "therapy_index": 6
            },
            {
                "therapy": "Corticosteroids",
                "day": 6,
                "therapy_index": 10
            },
            {
                "therapy": "Tracheostomy",
                "day": 8,
                "therapy_index": 1
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 2,
                "therapy_index": 3
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 11,
                "therapy_index": 2
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 13,
                "therapy_index": 8
            },
            {
                "therapy": "Corticosteroids",
                "day": 15,
                "therapy_index": 10
            },
            {
                "therapy": "Therapeutic heparin",
                "day": 15,
                "therapy_index": 11
            },
            {
                "therapy": "Prone positioning",
                "day": 16,
                "therapy_index": 4
            },
            {
                "therapy": "Inhaled prostacyclin",
                "day": 17,
                "therapy_index": 6
            },
            {
                "therapy": "Tracheostomy",
                "day": 27,
                "therapy_index": 1
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 3,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Azithromycin",
                "day": 1,
                "therapy_index": 13
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 2,
                "therapy_index": 3
            },
            {
                "therapy": "Corticosteroids",
                "day": 2,
                "therapy_index": 10
            },
            {
                "therapy": "Diuretics",
                "day": 3,
                "therapy_index": 9
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 4,
                "therapy_index": 2
            }
        ]
    },
    {
        "age": 6,
        "gender": 2,
        "ARDS": 3,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 2,
                "therapy_index": 8
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 2,
                "therapy_index": 17
            }
        ]
    },
    {
        "age": 5,
        "gender": 1,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 2,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 2,
                "therapy_index": 4
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 2,
                "therapy_index": 17
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 7,
                "therapy_index": 8
            },
            {
                "therapy": "Anti-Fungal",
                "day": 11,
                "therapy_index": 18
            },
            {
                "therapy": "Tracheostomy",
                "day": 25,
                "therapy_index": 1
            },
            {
                "therapy": "Inhaled prostacyclin",
                "day": 25,
                "therapy_index": 6
            }
        ]
    },
    {
        "age": 6,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 7,
                "therapy_index": 2
            },
            {
                "therapy": "Diuretics",
                "day": 8,
                "therapy_index": 9
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 13,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 16,
                "therapy_index": 4
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 2,
                "therapy_index": 17
            }
        ]
    },
    {
        "age": 6,
        "gender": 2,
        "ARDS": 3,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 3,
                "therapy_index": 8
            },
            {
                "therapy": "Prone positioning",
                "day": 5,
                "therapy_index": 4
            },
            {
                "therapy": "Corticosteroids",
                "day": 6,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 3,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Prone positioning",
                "day": 1,
                "therapy_index": 4
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 2,
                "therapy_index": 3
            },
            {
                "therapy": "Diuretics",
                "day": 7,
                "therapy_index": 9
            },
            {
                "therapy": "Corticosteroids",
                "day": 14,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 3,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Diuretics",
                "day": 8,
                "therapy_index": 9
            },
            {
                "therapy": "Corticosteroids",
                "day": 11,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Azithromycin",
                "day": 2,
                "therapy_index": 13
            },
            {
                "therapy": "Hydroxychloroquine",
                "day": 2,
                "therapy_index": 14
            },
            {
                "therapy": "Prone positioning",
                "day": 3,
                "therapy_index": 4
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 3,
                "therapy_index": 8
            },
            {
                "therapy": "Inhaled nitric oxide",
                "day": 6,
                "therapy_index": 5
            },
            {
                "therapy": "Corticosteroids",
                "day": 7,
                "therapy_index": 10
            },
            {
                "therapy": "Bronchoscopy",
                "day": 8,
                "therapy_index": 7
            },
            {
                "therapy": "Tracheostomy",
                "day": 24,
                "therapy_index": 1
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 2,
                "therapy_index": 3
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Corticosteroids",
                "day": 2,
                "therapy_index": 10
            },
            {
                "therapy": "Anankinra",
                "day": 2,
                "therapy_index": 16
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 5,
                "therapy_index": 8
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 8,
                "therapy_index": 2
            },
            {
                "therapy": "Inhaled nitric oxide",
                "day": 8,
                "therapy_index": 5
            },
            {
                "therapy": "Inhaled prostacyclin",
                "day": 8,
                "therapy_index": 6
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 1,
                "therapy_index": 4
            },
            {
                "therapy": "Diuretics",
                "day": 1,
                "therapy_index": 9
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 2,
                "therapy_index": 8
            }
        ]
    },
    {
        "age": 3,
        "gender": 1,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Azithromycin",
                "day": 2,
                "therapy_index": 13
            },
            {
                "therapy": "Hydroxychloroquine",
                "day": 2,
                "therapy_index": 14
            },
            {
                "therapy": "Diuretics",
                "day": 3,
                "therapy_index": 9
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 7,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 7,
                "therapy_index": 4
            },
            {
                "therapy": "Inhaled nitric oxide",
                "day": 7,
                "therapy_index": 5
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 7,
                "therapy_index": 8
            },
            {
                "therapy": "Corticosteroids",
                "day": 13,
                "therapy_index": 10
            },
            {
                "therapy": "Tracheostomy",
                "day": 16,
                "therapy_index": 1
            }
        ]
    },
    {
        "age": 1,
        "gender": 2,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Therapeutic heparin",
                "day": 1,
                "therapy_index": 11
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 2,
                "therapy_index": 3
            },
            {
                "therapy": "Diuretics",
                "day": 4,
                "therapy_index": 9
            },
            {
                "therapy": "Prone positioning",
                "day": 8,
                "therapy_index": 4
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 3,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 2,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 2,
                "therapy_index": 4
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 2,
                "therapy_index": 17
            }
        ]
    },
    {
        "age": 4,
        "gender": 1,
        "ARDS": 3,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 1,
                "therapy_index": 4
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Inhaled nitric oxide",
                "day": 4,
                "therapy_index": 5
            },
            {
                "therapy": "Corticosteroids",
                "day": 10,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 1,
                "therapy_index": 8
            },
            {
                "therapy": "Corticosteroids",
                "day": 2,
                "therapy_index": 10
            },
            {
                "therapy": "Azithromycin",
                "day": 3,
                "therapy_index": 13
            },
            {
                "therapy": "Hydroxychloroquine",
                "day": 4,
                "therapy_index": 14
            },
            {
                "therapy": "Inhaled nitric oxide",
                "day": 9,
                "therapy_index": 5
            },
            {
                "therapy": "Bronchoscopy",
                "day": 14,
                "therapy_index": 7
            },
            {
                "therapy": "Tracheostomy",
                "day": 27,
                "therapy_index": 1
            },
            {
                "therapy": "Diuretics",
                "day": 35,
                "therapy_index": 9
            }
        ]
    },
    {
        "age": 5,
        "gender": 1,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Diuretics",
                "day": 4,
                "therapy_index": 9
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 6,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 6,
                "therapy_index": 4
            },
            {
                "therapy": "Corticosteroids",
                "day": 13,
                "therapy_index": 10
            },
            {
                "therapy": "Tracheostomy",
                "day": 18,
                "therapy_index": 1
            }
        ]
    },
    {
        "age": 5,
        "gender": 1,
        "ARDS": 3,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Diuretics",
                "day": 4,
                "therapy_index": 9
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 3,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 3,
                "therapy_index": 4
            },
            {
                "therapy": "Inhaled prostacyclin",
                "day": 5,
                "therapy_index": 6
            },
            {
                "therapy": "Inhaled nitric oxide",
                "day": 8,
                "therapy_index": 5
            },
            {
                "therapy": "Corticosteroids",
                "day": 14,
                "therapy_index": 10
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 15,
                "therapy_index": 2
            },
            {
                "therapy": "Tracheostomy",
                "day": 27,
                "therapy_index": 1
            },
            {
                "therapy": "Bronchoscopy",
                "day": 30,
                "therapy_index": 7
            }
        ]
    },
    {
        "age": 6,
        "gender": 1,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Bronchoscopy",
                "day": 1,
                "therapy_index": 7
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Prone positioning",
                "day": 4,
                "therapy_index": 4
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 5,
                "therapy_index": 3
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 5,
                "therapy_index": 8
            },
            {
                "therapy": "Corticosteroids",
                "day": 6,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 5,
        "gender": 1,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Corticosteroids",
                "day": 2,
                "therapy_index": 10
            },
            {
                "therapy": "Diuretics",
                "day": 3,
                "therapy_index": 9
            }
        ]
    },
    {
        "age": 6,
        "gender": 2,
        "ARDS": 3,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Prone positioning",
                "day": 1,
                "therapy_index": 4
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 2,
                "therapy_index": 2
            },
            {
                "therapy": "Inhaled nitric oxide",
                "day": 3,
                "therapy_index": 5
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 5,
                "therapy_index": 8
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 7,
                "therapy_index": 3
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 3,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 2,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 2,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 3,
                "therapy_index": 4
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 3,
                "therapy_index": 8
            },
            {
                "therapy": "Diuretics",
                "day": 3,
                "therapy_index": 9
            },
            {
                "therapy": "Therapeutic heparin",
                "day": 9,
                "therapy_index": 11
            },
            {
                "therapy": "Tracheostomy",
                "day": 19,
                "therapy_index": 1
            },
            {
                "therapy": "Bronchoscopy",
                "day": 25,
                "therapy_index": 7
            },
            {
                "therapy": "Corticosteroids",
                "day": 31,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 5,
        "gender": 1,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Tocilizumab",
                "day": 2,
                "therapy_index": 15
            },
            {
                "therapy": "Diuretics",
                "day": 6,
                "therapy_index": 9
            }
        ]
    },
    {
        "age": 1,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Corticosteroids",
                "day": 1,
                "therapy_index": 10
            },
            {
                "therapy": "Bronchoscopy",
                "day": 2,
                "therapy_index": 7
            },
            {
                "therapy": "Azithromycin",
                "day": 2,
                "therapy_index": 13
            },
            {
                "therapy": "Tocilizumab",
                "day": 3,
                "therapy_index": 15
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 3,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Corticosteroids",
                "day": 2,
                "therapy_index": 10
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 14,
                "therapy_index": 2
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 3,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Diuretics",
                "day": 1,
                "therapy_index": 9
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 1,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Azithromycin",
                "day": 1,
                "therapy_index": 13
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 8,
                "therapy_index": 8
            }
        ]
    },
    {
        "age": 5,
        "gender": 1,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Prone positioning",
                "day": 2,
                "therapy_index": 4
            },
            {
                "therapy": "Azithromycin",
                "day": 2,
                "therapy_index": 13
            },
            {
                "therapy": "Hydroxychloroquine",
                "day": 2,
                "therapy_index": 14
            },
            {
                "therapy": "Diuretics",
                "day": 3,
                "therapy_index": 9
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 5,
                "therapy_index": 3
            },
            {
                "therapy": "Corticosteroids",
                "day": 5,
                "therapy_index": 10
            },
            {
                "therapy": "Tracheostomy",
                "day": 15,
                "therapy_index": 1
            }
        ]
    },
    {
        "age": 3,
        "gender": 2,
        "ARDS": 3,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 2,
                "therapy_index": 2
            },
            {
                "therapy": "Diuretics",
                "day": 4,
                "therapy_index": 9
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 8,
                "therapy_index": 8
            },
            {
                "therapy": "Corticosteroids",
                "day": 14,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 6,
        "gender": 2,
        "ARDS": 1,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 2,
                "therapy_index": 17
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 6,
                "therapy_index": 3
            },
            {
                "therapy": "Corticosteroids",
                "day": 7,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 6,
        "gender": 2,
        "ARDS": 3,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 4,
                "therapy_index": 8
            },
            {
                "therapy": "Anti-Fungal",
                "day": 18,
                "therapy_index": 18
            }
        ]
    },
    {
        "age": 3,
        "gender": 2,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Corticosteroids",
                "day": 2,
                "therapy_index": 10
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 4,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 4,
                "therapy_index": 4
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 9,
                "therapy_index": 8
            },
            {
                "therapy": "Tracheostomy",
                "day": 21,
                "therapy_index": 1
            }
        ]
    },
    {
        "age": 3,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 1,
                "therapy_index": 8
            },
            {
                "therapy": "Diuretics",
                "day": 1,
                "therapy_index": 9
            },
            {
                "therapy": "Corticosteroids",
                "day": 1,
                "therapy_index": 10
            },
            {
                "therapy": "Prone positioning",
                "day": 6,
                "therapy_index": 4
            },
            {
                "therapy": "Tocilizumab",
                "day": 25,
                "therapy_index": 15
            }
        ]
    },
    {
        "age": 1,
        "gender": 2,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            }
        ]
    },
    {
        "age": 6,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 3,
                "therapy_index": 8
            },
            {
                "therapy": "Prone positioning",
                "day": 6,
                "therapy_index": 4
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 7,
                "therapy_index": 3
            }
        ]
    },
    {
        "age": 6,
        "gender": 2,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Diuretics",
                "day": 4,
                "therapy_index": 9
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 5,
                "therapy_index": 2
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 1,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 7,
                "therapy_index": 2
            },
            {
                "therapy": "Prone positioning",
                "day": 9,
                "therapy_index": 4
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 11,
                "therapy_index": 3
            },
            {
                "therapy": "Diuretics",
                "day": 11,
                "therapy_index": 9
            },
            {
                "therapy": "Anti-Fungal",
                "day": 11,
                "therapy_index": 18
            }
        ]
    },
    {
        "age": 1,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 2,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 2,
                "therapy_index": 3
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Remdesivir",
                "day": 4,
                "therapy_index": 12
            },
            {
                "therapy": "Inhaled nitric oxide",
                "day": 12,
                "therapy_index": 5
            },
            {
                "therapy": "Inhaled prostacyclin",
                "day": 16,
                "therapy_index": 6
            },
            {
                "therapy": "Bronchoscopy",
                "day": 17,
                "therapy_index": 7
            },
            {
                "therapy": "Tracheostomy",
                "day": 23,
                "therapy_index": 1
            }
        ]
    },
    {
        "age": 6,
        "gender": 2,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Diuretics",
                "day": 1,
                "therapy_index": 9
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 2,
                "therapy_index": 3
            },
            {
                "therapy": "Corticosteroids",
                "day": 21,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 6,
        "gender": 2,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Hydroxychloroquine",
                "day": 1,
                "therapy_index": 14
            },
            {
                "therapy": "Azithromycin",
                "day": 2,
                "therapy_index": 13
            },
            {
                "therapy": "Diuretics",
                "day": 3,
                "therapy_index": 9
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 7,
                "therapy_index": 8
            }
        ]
    },
    {
        "age": 6,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 2,
                "therapy_index": 2
            },
            {
                "therapy": "Bronchoscopy",
                "day": 2,
                "therapy_index": 7
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Corticosteroids",
                "day": 7,
                "therapy_index": 10
            },
            {
                "therapy": "Tracheostomy",
                "day": 12,
                "therapy_index": 1
            }
        ]
    },
    {
        "age": 3,
        "gender": 2,
        "ARDS": 3,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Diuretics",
                "day": 1,
                "therapy_index": 9
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 3,
                "therapy_index": 8
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 5,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 5,
                "therapy_index": 3
            },
            {
                "therapy": "Corticosteroids",
                "day": 5,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 3,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 3,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 3,
                "therapy_index": 3
            },
            {
                "therapy": "Diuretics",
                "day": 5,
                "therapy_index": 9
            },
            {
                "therapy": "Prone positioning",
                "day": 6,
                "therapy_index": 4
            },
            {
                "therapy": "Inhaled prostacyclin",
                "day": 6,
                "therapy_index": 6
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 6,
                "therapy_index": 8
            },
            {
                "therapy": "Inhaled nitric oxide",
                "day": 8,
                "therapy_index": 5
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 3,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Prone positioning",
                "day": 2,
                "therapy_index": 4
            },
            {
                "therapy": "Tracheostomy",
                "day": 6,
                "therapy_index": 1
            }
        ]
    },
    {
        "age": 5,
        "gender": 1,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 2,
                "therapy_index": 8
            },
            {
                "therapy": "Tracheostomy",
                "day": 8,
                "therapy_index": 1
            }
        ]
    },
    {
        "age": 2,
        "gender": 2,
        "ARDS": 3,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 3,
                "therapy_index": 4
            },
            {
                "therapy": "Corticosteroids",
                "day": 3,
                "therapy_index": 10
            },
            {
                "therapy": "Diuretics",
                "day": 16,
                "therapy_index": 9
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 5,
                "therapy_index": 8
            }
        ]
    },
    {
        "age": 4,
        "gender": 1,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 2,
                "therapy_index": 17
            },
            {
                "therapy": "Tracheostomy",
                "day": 12,
                "therapy_index": 1
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Inhaled prostacyclin",
                "day": 2,
                "therapy_index": 6
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 4,
                "therapy_index": 3
            },
            {
                "therapy": "Diuretics",
                "day": 4,
                "therapy_index": 9
            },
            {
                "therapy": "Corticosteroids",
                "day": 6,
                "therapy_index": 10
            },
            {
                "therapy": "Inhaled nitric oxide",
                "day": 7,
                "therapy_index": 5
            },
            {
                "therapy": "Prone positioning",
                "day": 11,
                "therapy_index": 4
            },
            {
                "therapy": "Bronchoscopy",
                "day": 17,
                "therapy_index": 7
            },
            {
                "therapy": "Tracheostomy",
                "day": 34,
                "therapy_index": 1
            }
        ]
    },
    {
        "age": 3,
        "gender": 2,
        "ARDS": 3,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Diuretics",
                "day": 1,
                "therapy_index": 9
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Prone positioning",
                "day": 2,
                "therapy_index": 4
            },
            {
                "therapy": "Corticosteroids",
                "day": 3,
                "therapy_index": 10
            },
            {
                "therapy": "Azithromycin",
                "day": 3,
                "therapy_index": 13
            },
            {
                "therapy": "Tracheostomy",
                "day": 10,
                "therapy_index": 1
            },
            {
                "therapy": "Bronchoscopy",
                "day": 10,
                "therapy_index": 7
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 5,
                "therapy_index": 2
            },
            {
                "therapy": "Prone positioning",
                "day": 6,
                "therapy_index": 4
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 7,
                "therapy_index": 8
            },
            {
                "therapy": "Corticosteroids",
                "day": 8,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 2,
        "gender": 1,
        "ARDS": 3,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Diuretics",
                "day": 3,
                "therapy_index": 9
            },
            {
                "therapy": "Corticosteroids",
                "day": 3,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 4,
        "gender": 1,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 1,
                "therapy_index": 4
            },
            {
                "therapy": "Diuretics",
                "day": 1,
                "therapy_index": 9
            },
            {
                "therapy": "Azithromycin",
                "day": 1,
                "therapy_index": 13
            },
            {
                "therapy": "Hydroxychloroquine",
                "day": 1,
                "therapy_index": 14
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 2,
                "therapy_index": 2
            },
            {
                "therapy": "Inhaled nitric oxide",
                "day": 2,
                "therapy_index": 5
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Anti-Fungal",
                "day": 1,
                "therapy_index": 18
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 2,
                "therapy_index": 8
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Corticosteroids",
                "day": 4,
                "therapy_index": 10
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 5,
                "therapy_index": 2
            },
            {
                "therapy": "Therapeutic heparin",
                "day": 5,
                "therapy_index": 11
            },
            {
                "therapy": "Anankinra",
                "day": 5,
                "therapy_index": 16
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Tracheostomy",
                "day": 6,
                "therapy_index": 1
            }
        ]
    },
    {
        "age": 3,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 1,
                "therapy_index": 4
            },
            {
                "therapy": "Diuretics",
                "day": 4,
                "therapy_index": 9
            }
        ]
    },
    {
        "age": 5,
        "gender": 1,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 2,
                "therapy_index": 8
            },
            {
                "therapy": "Therapeutic heparin",
                "day": 2,
                "therapy_index": 11
            },
            {
                "therapy": "Corticosteroids",
                "day": 5,
                "therapy_index": 10
            },
            {
                "therapy": "Diuretics",
                "day": 6,
                "therapy_index": 9
            },
            {
                "therapy": "Tracheostomy",
                "day": 12,
                "therapy_index": 1
            },
            {
                "therapy": "Bronchoscopy",
                "day": 24,
                "therapy_index": 7
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 1,
                "therapy_index": 4
            },
            {
                "therapy": "Diuretics",
                "day": 1,
                "therapy_index": 9
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 3,
                "therapy_index": 17
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 3,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Diuretics",
                "day": 3,
                "therapy_index": 9
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 4,
                "therapy_index": 2
            },
            {
                "therapy": "Inhaled prostacyclin",
                "day": 4,
                "therapy_index": 6
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 7,
                "therapy_index": 8
            },
            {
                "therapy": "Prone positioning",
                "day": 8,
                "therapy_index": 4
            },
            {
                "therapy": "Corticosteroids",
                "day": 11,
                "therapy_index": 10
            },
            {
                "therapy": "Therapeutic heparin",
                "day": 12,
                "therapy_index": 11
            },
            {
                "therapy": "Inhaled nitric oxide",
                "day": 13,
                "therapy_index": 5
            }
        ]
    },
    {
        "age": 3,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 1,
                "therapy_index": 8
            },
            {
                "therapy": "Azithromycin",
                "day": 1,
                "therapy_index": 13
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 2,
                "therapy_index": 2
            },
            {
                "therapy": "Prone positioning",
                "day": 2,
                "therapy_index": 4
            }
        ]
    },
    {
        "age": 6,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            }
        ]
    },
    {
        "age": 5,
        "gender": 1,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Azithromycin",
                "day": 5,
                "therapy_index": 13
            }
        ]
    },
    {
        "age": 3,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Diuretics",
                "day": 1,
                "therapy_index": 9
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Hydroxychloroquine",
                "day": 7,
                "therapy_index": 14
            },
            {
                "therapy": "Corticosteroids",
                "day": 8,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 3,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Prone positioning",
                "day": 1,
                "therapy_index": 4
            },
            {
                "therapy": "Diuretics",
                "day": 1,
                "therapy_index": 9
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 2,
                "therapy_index": 3
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 3,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 2,
                "therapy_index": 3
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 3,
                "therapy_index": 8
            },
            {
                "therapy": "Therapeutic heparin",
                "day": 6,
                "therapy_index": 11
            }
        ]
    },
    {
        "age": 6,
        "gender": 2,
        "ARDS": 3,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Prone positioning",
                "day": 1,
                "therapy_index": 4
            },
            {
                "therapy": "Corticosteroids",
                "day": 1,
                "therapy_index": 10
            },
            {
                "therapy": "Hydroxychloroquine",
                "day": 1,
                "therapy_index": 14
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Diuretics",
                "day": 6,
                "therapy_index": 9
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 3,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 2,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 4,
                "therapy_index": 4
            },
            {
                "therapy": "Inhaled prostacyclin",
                "day": 4,
                "therapy_index": 6
            },
            {
                "therapy": "Diuretics",
                "day": 4,
                "therapy_index": 9
            },
            {
                "therapy": "Corticosteroids",
                "day": 5,
                "therapy_index": 10
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 6,
                "therapy_index": 2
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 7,
                "therapy_index": 8
            },
            {
                "therapy": "Tracheostomy",
                "day": 15,
                "therapy_index": 1
            }
        ]
    },
    {
        "age": 4,
        "gender": 1,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 3,
                "therapy_index": 2
            },
            {
                "therapy": "Corticosteroids",
                "day": 6,
                "therapy_index": 10
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 7,
                "therapy_index": 3
            },
            {
                "therapy": "Tracheostomy",
                "day": 15,
                "therapy_index": 1
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 2,
                "therapy_index": 3
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 3,
                "therapy_index": 8
            },
            {
                "therapy": "Prone positioning",
                "day": 7,
                "therapy_index": 4
            },
            {
                "therapy": "Inhaled nitric oxide",
                "day": 11,
                "therapy_index": 5
            }
        ]
    },
    {
        "age": 6,
        "gender": 2,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Corticosteroids",
                "day": 1,
                "therapy_index": 10
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Tracheostomy",
                "day": 10,
                "therapy_index": 1
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 2,
                "therapy_index": 3
            },
            {
                "therapy": "Azithromycin",
                "day": 2,
                "therapy_index": 13
            },
            {
                "therapy": "Hydroxychloroquine",
                "day": 2,
                "therapy_index": 14
            },
            {
                "therapy": "Diuretics",
                "day": 3,
                "therapy_index": 9
            },
            {
                "therapy": "Inhaled nitric oxide",
                "day": 4,
                "therapy_index": 5
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 4,
                "therapy_index": 8
            },
            {
                "therapy": "Prone positioning",
                "day": 20,
                "therapy_index": 4
            },
            {
                "therapy": "Corticosteroids",
                "day": 20,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 3,
        "gender": 2,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Diuretics",
                "day": 3,
                "therapy_index": 9
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 5,
                "therapy_index": 3
            }
        ]
    },
    {
        "age": 1,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Azithromycin",
                "day": 1,
                "therapy_index": 13
            },
            {
                "therapy": "Hydroxychloroquine",
                "day": 2,
                "therapy_index": 14
            },
            {
                "therapy": "Diuretics",
                "day": 3,
                "therapy_index": 9
            },
            {
                "therapy": "Corticosteroids",
                "day": 3,
                "therapy_index": 10
            },
            {
                "therapy": "Prone positioning",
                "day": 6,
                "therapy_index": 4
            }
        ]
    },
    {
        "age": 4,
        "gender": 1,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Diuretics",
                "day": 1,
                "therapy_index": 9
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 3,
                "therapy_index": 2
            },
            {
                "therapy": "Inhaled prostacyclin",
                "day": 3,
                "therapy_index": 6
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 4,
                "therapy_index": 3
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 4,
                "therapy_index": 8
            },
            {
                "therapy": "Corticosteroids",
                "day": 4,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 6,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Diuretics",
                "day": 1,
                "therapy_index": 9
            },
            {
                "therapy": "Corticosteroids",
                "day": 4,
                "therapy_index": 10
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 7,
                "therapy_index": 2
            },
            {
                "therapy": "Prone positioning",
                "day": 7,
                "therapy_index": 4
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 2,
                "therapy_index": 3
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 2,
                "therapy_index": 17
            }
        ]
    },
    {
        "age": 4,
        "gender": 1,
        "ARDS": 3,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 3,
                "therapy_index": 2
            },
            {
                "therapy": "Prone positioning",
                "day": 3,
                "therapy_index": 4
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 3,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 2,
                "therapy_index": 2
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 2,
                "therapy_index": 8
            },
            {
                "therapy": "Inhaled nitric oxide",
                "day": 3,
                "therapy_index": 5
            },
            {
                "therapy": "Prone positioning",
                "day": 8,
                "therapy_index": 4
            },
            {
                "therapy": "Corticosteroids",
                "day": 15,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 5,
        "gender": 1,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Diuretics",
                "day": 3,
                "therapy_index": 9
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 9,
                "therapy_index": 2
            },
            {
                "therapy": "Corticosteroids",
                "day": 12,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 3,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Diuretics",
                "day": 1,
                "therapy_index": 9
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 4,
                "therapy_index": 2
            }
        ]
    },
    {
        "age": 3,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Azithromycin",
                "day": 1,
                "therapy_index": 13
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Hydroxychloroquine",
                "day": 2,
                "therapy_index": 14
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 3,
                "therapy_index": 3
            },
            {
                "therapy": "Tracheostomy",
                "day": 12,
                "therapy_index": 1
            }
        ]
    },
    {
        "age": 6,
        "gender": 2,
        "ARDS": 1,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            }
        ]
    },
    {
        "age": 2,
        "gender": 2,
        "ARDS": 3,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 2,
                "therapy_index": 3
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Corticosteroids",
                "day": 5,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 5,
        "gender": 1,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 2,
                "therapy_index": 17
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 7,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 8,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 8,
                "therapy_index": 4
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 12,
                "therapy_index": 8
            },
            {
                "therapy": "Tracheostomy",
                "day": 16,
                "therapy_index": 1
            }
        ]
    },
    {
        "age": 4,
        "gender": 1,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Corticosteroids",
                "day": 5,
                "therapy_index": 10
            },
            {
                "therapy": "Diuretics",
                "day": 6,
                "therapy_index": 9
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 8,
                "therapy_index": 3
            },
            {
                "therapy": "Tracheostomy",
                "day": 9,
                "therapy_index": 1
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Diuretics",
                "day": 3,
                "therapy_index": 9
            },
            {
                "therapy": "Tracheostomy",
                "day": 7,
                "therapy_index": 1
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 7,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 9,
                "therapy_index": 3
            },
            {
                "therapy": "Bronchoscopy",
                "day": 9,
                "therapy_index": 7
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 10,
                "therapy_index": 8
            }
        ]
    },
    {
        "age": 2,
        "gender": 2,
        "ARDS": 1,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 1,
                "therapy_index": 8
            },
            {
                "therapy": "Diuretics",
                "day": 1,
                "therapy_index": 9
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 3,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 3,
                "therapy_index": 3
            },
            {
                "therapy": "Corticosteroids",
                "day": 5,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 3,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Diuretics",
                "day": 1,
                "therapy_index": 9
            },
            {
                "therapy": "Corticosteroids",
                "day": 11,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 1,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Hydroxychloroquine",
                "day": 2,
                "therapy_index": 14
            },
            {
                "therapy": "Prone positioning",
                "day": 4,
                "therapy_index": 4
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 4,
                "therapy_index": 8
            },
            {
                "therapy": "Corticosteroids",
                "day": 11,
                "therapy_index": 10
            },
            {
                "therapy": "Diuretics",
                "day": 32,
                "therapy_index": 9
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 2,
                "therapy_index": 4
            },
            {
                "therapy": "Corticosteroids",
                "day": 3,
                "therapy_index": 10
            },
            {
                "therapy": "Diuretics",
                "day": 7,
                "therapy_index": 9
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 8,
                "therapy_index": 8
            },
            {
                "therapy": "Therapeutic heparin",
                "day": 8,
                "therapy_index": 11
            },
            {
                "therapy": "Anankinra",
                "day": 13,
                "therapy_index": 16
            },
            {
                "therapy": "Inhaled prostacyclin",
                "day": 14,
                "therapy_index": 6
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 3,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Corticosteroids",
                "day": 2,
                "therapy_index": 10
            },
            {
                "therapy": "Diuretics",
                "day": 4,
                "therapy_index": 9
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 7,
                "therapy_index": 8
            },
            {
                "therapy": "Prone positioning",
                "day": 12,
                "therapy_index": 4
            }
        ]
    },
    {
        "age": 6,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 2,
                "therapy_index": 2
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Tracheostomy",
                "day": 6,
                "therapy_index": 1
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 7,
                "therapy_index": 3
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 9,
                "therapy_index": 8
            }
        ]
    },
    {
        "age": 4,
        "gender": 1,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 1,
                "therapy_index": 8
            },
            {
                "therapy": "Corticosteroids",
                "day": 1,
                "therapy_index": 10
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 3,
                "therapy_index": 2
            },
            {
                "therapy": "Prone positioning",
                "day": 3,
                "therapy_index": 4
            },
            {
                "therapy": "Hydroxychloroquine",
                "day": 3,
                "therapy_index": 14
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 2,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 4,
                "therapy_index": 4
            },
            {
                "therapy": "Diuretics",
                "day": 4,
                "therapy_index": 9
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 9,
                "therapy_index": 8
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 2,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 4,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 4,
                "therapy_index": 4
            },
            {
                "therapy": "Diuretics",
                "day": 4,
                "therapy_index": 9
            },
            {
                "therapy": "Corticosteroids",
                "day": 6,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 6,
        "gender": 1,
        "ARDS": 3,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 2,
                "therapy_index": 4
            },
            {
                "therapy": "Corticosteroids",
                "day": 4,
                "therapy_index": 10
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 8,
                "therapy_index": 8
            },
            {
                "therapy": "Diuretics",
                "day": 15,
                "therapy_index": 9
            }
        ]
    },
    {
        "age": 6,
        "gender": 1,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Diuretics",
                "day": 1,
                "therapy_index": 9
            },
            {
                "therapy": "Tracheostomy",
                "day": 3,
                "therapy_index": 1
            },
            {
                "therapy": "Prone positioning",
                "day": 3,
                "therapy_index": 4
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 1,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Diuretics",
                "day": 3,
                "therapy_index": 9
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 12,
                "therapy_index": 3
            },
            {
                "therapy": "Bronchoscopy",
                "day": 12,
                "therapy_index": 7
            },
            {
                "therapy": "Corticosteroids",
                "day": 15,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 1,
        "gender": 1,
        "ARDS": 3,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Hydroxychloroquine",
                "day": 1,
                "therapy_index": 14
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 2,
                "therapy_index": 2
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Azithromycin",
                "day": 2,
                "therapy_index": 13
            },
            {
                "therapy": "Prone positioning",
                "day": 4,
                "therapy_index": 4
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Prone positioning",
                "day": 1,
                "therapy_index": 4
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 2,
                "therapy_index": 3
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 3,
                "therapy_index": 8
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 4,
                "therapy_index": 2
            }
        ]
    },
    {
        "age": 6,
        "gender": 1,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 1,
                "therapy_index": 8
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Diuretics",
                "day": 6,
                "therapy_index": 9
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 7,
                "therapy_index": 2
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Diuretics",
                "day": 3,
                "therapy_index": 9
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 6,
                "therapy_index": 8
            },
            {
                "therapy": "Tracheostomy",
                "day": 13,
                "therapy_index": 1
            }
        ]
    },
    {
        "age": 3,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 4,
                "therapy_index": 2
            },
            {
                "therapy": "Diuretics",
                "day": 4,
                "therapy_index": 9
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 5,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 5,
                "therapy_index": 4
            }
        ]
    },
    {
        "age": 6,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Diuretics",
                "day": 1,
                "therapy_index": 9
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 1,
                "therapy_index": 8
            },
            {
                "therapy": "Azithromycin",
                "day": 1,
                "therapy_index": 13
            },
            {
                "therapy": "Diuretics",
                "day": 13,
                "therapy_index": 9
            }
        ]
    },
    {
        "age": 3,
        "gender": 2,
        "ARDS": 3,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 1,
                "therapy_index": 4
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 2,
                "therapy_index": 2
            },
            {
                "therapy": "Diuretics",
                "day": 3,
                "therapy_index": 9
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 3,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 4,
                "therapy_index": 3
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 10,
                "therapy_index": 8
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 12,
                "therapy_index": 2
            }
        ]
    },
    {
        "age": 4,
        "gender": 1,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 1,
                "therapy_index": 4
            },
            {
                "therapy": "Diuretics",
                "day": 1,
                "therapy_index": 9
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 6,
                "therapy_index": 2
            },
            {
                "therapy": "Inhaled nitric oxide",
                "day": 9,
                "therapy_index": 5
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 3,
                "therapy_index": 3
            }
        ]
    },
    {
        "age": 3,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Azithromycin",
                "day": 1,
                "therapy_index": 13
            },
            {
                "therapy": "Hydroxychloroquine",
                "day": 1,
                "therapy_index": 14
            },
            {
                "therapy": "Prone positioning",
                "day": 2,
                "therapy_index": 4
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Corticosteroids",
                "day": 1,
                "therapy_index": 10
            },
            {
                "therapy": "Hydroxychloroquine",
                "day": 1,
                "therapy_index": 14
            },
            {
                "therapy": "Prone positioning",
                "day": 2,
                "therapy_index": 4
            },
            {
                "therapy": "Diuretics",
                "day": 5,
                "therapy_index": 9
            },
            {
                "therapy": "Tocilizumab",
                "day": 6,
                "therapy_index": 15
            }
        ]
    },
    {
        "age": 6,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Azithromycin",
                "day": 1,
                "therapy_index": 13
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 2,
                "therapy_index": 3
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 3,
                "therapy_index": 2
            },
            {
                "therapy": "Prone positioning",
                "day": 6,
                "therapy_index": 4
            },
            {
                "therapy": "Corticosteroids",
                "day": 6,
                "therapy_index": 10
            },
            {
                "therapy": "Inhaled prostacyclin",
                "day": 9,
                "therapy_index": 6
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 3,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 2,
                "therapy_index": 4
            },
            {
                "therapy": "Inhaled nitric oxide",
                "day": 2,
                "therapy_index": 5
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Azithromycin",
                "day": 2,
                "therapy_index": 13
            },
            {
                "therapy": "Hydroxychloroquine",
                "day": 2,
                "therapy_index": 14
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 5,
                "therapy_index": 8
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 3,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Diuretics",
                "day": 1,
                "therapy_index": 9
            },
            {
                "therapy": "Corticosteroids",
                "day": 1,
                "therapy_index": 10
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 2,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 2,
                "therapy_index": 4
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 3,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 2,
                "therapy_index": 8
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Corticosteroids",
                "day": 2,
                "therapy_index": 10
            },
            {
                "therapy": "Therapeutic heparin",
                "day": 12,
                "therapy_index": 11
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 3,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            }
        ]
    },
    {
        "age": 2,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Azithromycin",
                "day": 2,
                "therapy_index": 13
            },
            {
                "therapy": "Prone positioning",
                "day": 4,
                "therapy_index": 4
            },
            {
                "therapy": "Inhaled nitric oxide",
                "day": 5,
                "therapy_index": 5
            },
            {
                "therapy": "Corticosteroids",
                "day": 8,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 6,
        "gender": 1,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Diuretics",
                "day": 3,
                "therapy_index": 9
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 6,
                "therapy_index": 8
            },
            {
                "therapy": "Tracheostomy",
                "day": 8,
                "therapy_index": 1
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 8,
                "therapy_index": 2
            },
            {
                "therapy": "Prone positioning",
                "day": 9,
                "therapy_index": 4
            },
            {
                "therapy": "Corticosteroids",
                "day": 25,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Prone positioning",
                "day": 1,
                "therapy_index": 4
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 2,
                "therapy_index": 3
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 3,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Corticosteroids",
                "day": 1,
                "therapy_index": 10
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Diuretics",
                "day": 3,
                "therapy_index": 9
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 5,
                "therapy_index": 2
            }
        ]
    },
    {
        "age": 2,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Diuretics",
                "day": 1,
                "therapy_index": 9
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Diuretics",
                "day": 3,
                "therapy_index": 9
            },
            {
                "therapy": "Prone positioning",
                "day": 7,
                "therapy_index": 4
            },
            {
                "therapy": "Tracheostomy",
                "day": 12,
                "therapy_index": 1
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 20,
                "therapy_index": 2
            },
            {
                "therapy": "Corticosteroids",
                "day": 25,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 6,
        "gender": 2,
        "ARDS": 1,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Bronchoscopy",
                "day": 1,
                "therapy_index": 7
            },
            {
                "therapy": "Diuretics",
                "day": 1,
                "therapy_index": 9
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 3,
                "therapy_index": 3
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 3,
                "therapy_index": 8
            }
        ]
    },
    {
        "age": 3,
        "gender": 1,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Diuretics",
                "day": 1,
                "therapy_index": 9
            },
            {
                "therapy": "Therapeutic heparin",
                "day": 1,
                "therapy_index": 11
            },
            {
                "therapy": "Hydroxychloroquine",
                "day": 1,
                "therapy_index": 14
            },
            {
                "therapy": "Corticosteroids",
                "day": 9,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 5,
        "gender": 1,
        "ARDS": 3,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Hydroxychloroquine",
                "day": 1,
                "therapy_index": 14
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 2,
                "therapy_index": 2
            },
            {
                "therapy": "Prone positioning",
                "day": 2,
                "therapy_index": 4
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 4,
                "therapy_index": 8
            }
        ]
    },
    {
        "age": 3,
        "gender": 2,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 1,
                "therapy_index": 8
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 3,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Prone positioning",
                "day": 1,
                "therapy_index": 4
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 2,
                "therapy_index": 3
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 7,
                "therapy_index": 8
            },
            {
                "therapy": "Diuretics",
                "day": 15,
                "therapy_index": 9
            }
        ]
    },
    {
        "age": 6,
        "gender": 1,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Bronchoscopy",
                "day": 1,
                "therapy_index": 7
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Prone positioning",
                "day": 8,
                "therapy_index": 4
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 3,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Prone positioning",
                "day": 1,
                "therapy_index": 4
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 2,
                "therapy_index": 2
            },
            {
                "therapy": "Diuretics",
                "day": 6,
                "therapy_index": 9
            },
            {
                "therapy": "Corticosteroids",
                "day": 8,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 3,
                "therapy_index": 2
            }
        ]
    },
    {
        "age": 4,
        "gender": 1,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 2,
                "therapy_index": 2
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 3,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Diuretics",
                "day": 1,
                "therapy_index": 9
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Prone positioning",
                "day": 7,
                "therapy_index": 4
            },
            {
                "therapy": "Tracheostomy",
                "day": 11,
                "therapy_index": 1
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 3,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 3,
                "therapy_index": 2
            },
            {
                "therapy": "Diuretics",
                "day": 4,
                "therapy_index": 9
            },
            {
                "therapy": "Tocilizumab",
                "day": 6,
                "therapy_index": 15
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Tracheostomy",
                "day": 6,
                "therapy_index": 1
            }
        ]
    },
    {
        "age": 1,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 2,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 2,
                "therapy_index": 3
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Prone positioning",
                "day": 5,
                "therapy_index": 4
            },
            {
                "therapy": "Inhaled nitric oxide",
                "day": 9,
                "therapy_index": 5
            },
            {
                "therapy": "Tracheostomy",
                "day": 15,
                "therapy_index": 1
            }
        ]
    },
    {
        "age": 3,
        "gender": 1,
        "ARDS": 3,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 1,
                "therapy_index": 4
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 2,
                "therapy_index": 2
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Corticosteroids",
                "day": 1,
                "therapy_index": 10
            },
            {
                "therapy": "Anankinra",
                "day": 1,
                "therapy_index": 16
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 2,
                "therapy_index": 8
            },
            {
                "therapy": "Diuretics",
                "day": 6,
                "therapy_index": 9
            },
            {
                "therapy": "Tocilizumab",
                "day": 6,
                "therapy_index": 15
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 3,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Prone positioning",
                "day": 1,
                "therapy_index": 4
            },
            {
                "therapy": "Hydroxychloroquine",
                "day": 1,
                "therapy_index": 14
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 2,
                "therapy_index": 3
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Azithromycin",
                "day": 2,
                "therapy_index": 13
            },
            {
                "therapy": "Inhaled nitric oxide",
                "day": 3,
                "therapy_index": 5
            },
            {
                "therapy": "Corticosteroids",
                "day": 18,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Diuretics",
                "day": 1,
                "therapy_index": 9
            },
            {
                "therapy": "Tracheostomy",
                "day": 14,
                "therapy_index": 1
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 14,
                "therapy_index": 2
            }
        ]
    },
    {
        "age": 5,
        "gender": 1,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Tracheostomy",
                "day": 14,
                "therapy_index": 1
            },
            {
                "therapy": "Therapeutic heparin",
                "day": 16,
                "therapy_index": 11
            }
        ]
    },
    {
        "age": 2,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 3,
                "therapy_index": 2
            },
            {
                "therapy": "Corticosteroids",
                "day": 10,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 5,
        "gender": 1,
        "ARDS": 3,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 2,
                "therapy_index": 2
            },
            {
                "therapy": "Prone positioning",
                "day": 2,
                "therapy_index": 4
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Azithromycin",
                "day": 2,
                "therapy_index": 13
            },
            {
                "therapy": "Inhaled nitric oxide",
                "day": 3,
                "therapy_index": 5
            },
            {
                "therapy": "Corticosteroids",
                "day": 7,
                "therapy_index": 10
            },
            {
                "therapy": "Tracheostomy",
                "day": 14,
                "therapy_index": 1
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 3,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Inhaled nitric oxide",
                "day": 1,
                "therapy_index": 5
            },
            {
                "therapy": "Prone positioning",
                "day": 3,
                "therapy_index": 4
            },
            {
                "therapy": "Diuretics",
                "day": 3,
                "therapy_index": 9
            },
            {
                "therapy": "Corticosteroids",
                "day": 4,
                "therapy_index": 10
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 10,
                "therapy_index": 8
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 3,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Diuretics",
                "day": 9,
                "therapy_index": 9
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 2,
                "therapy_index": 2
            },
            {
                "therapy": "Prone positioning",
                "day": 2,
                "therapy_index": 4
            },
            {
                "therapy": "Diuretics",
                "day": 4,
                "therapy_index": 9
            },
            {
                "therapy": "Corticosteroids",
                "day": 8,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 6,
        "gender": 1,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Diuretics",
                "day": 1,
                "therapy_index": 9
            },
            {
                "therapy": "Corticosteroids",
                "day": 2,
                "therapy_index": 10
            },
            {
                "therapy": "Remdesivir",
                "day": 3,
                "therapy_index": 12
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 1,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 2,
                "therapy_index": 17
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 5,
                "therapy_index": 3
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Diuretics",
                "day": 1,
                "therapy_index": 9
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 2,
                "therapy_index": 8
            },
            {
                "therapy": "Prone positioning",
                "day": 5,
                "therapy_index": 4
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 6,
                "therapy_index": 3
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Diuretics",
                "day": 1,
                "therapy_index": 9
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 2,
                "therapy_index": 2
            }
        ]
    },
    {
        "age": 3,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Azithromycin",
                "day": 1,
                "therapy_index": 13
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 2,
                "therapy_index": 3
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 3,
                "therapy_index": 8
            },
            {
                "therapy": "Diuretics",
                "day": 3,
                "therapy_index": 9
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 2,
                "therapy_index": 2
            },
            {
                "therapy": "Prone positioning",
                "day": 4,
                "therapy_index": 4
            },
            {
                "therapy": "Diuretics",
                "day": 4,
                "therapy_index": 9
            },
            {
                "therapy": "Inhaled nitric oxide",
                "day": 5,
                "therapy_index": 5
            }
        ]
    },
    {
        "age": 2,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 2,
                "therapy_index": 2
            },
            {
                "therapy": "Azithromycin",
                "day": 2,
                "therapy_index": 13
            },
            {
                "therapy": "Hydroxychloroquine",
                "day": 2,
                "therapy_index": 14
            },
            {
                "therapy": "Diuretics",
                "day": 3,
                "therapy_index": 9
            }
        ]
    },
    {
        "age": 1,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 3,
                "therapy_index": 2
            }
        ]
    },
    {
        "age": 6,
        "gender": 2,
        "ARDS": 1,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 1,
                "therapy_index": 8
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 4,
                "therapy_index": 2
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Azithromycin",
                "day": 2,
                "therapy_index": 13
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 3,
                "therapy_index": 2
            },
            {
                "therapy": "Diuretics",
                "day": 3,
                "therapy_index": 9
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 5,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 5,
                "therapy_index": 4
            },
            {
                "therapy": "Bronchoscopy",
                "day": 5,
                "therapy_index": 7
            },
            {
                "therapy": "Corticosteroids",
                "day": 16,
                "therapy_index": 10
            },
            {
                "therapy": "Tracheostomy",
                "day": 19,
                "therapy_index": 1
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 3,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 2,
                "therapy_index": 4
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 5,
                "therapy_index": 2
            },
            {
                "therapy": "Corticosteroids",
                "day": 14,
                "therapy_index": 10
            },
            {
                "therapy": "Diuretics",
                "day": 17,
                "therapy_index": 9
            }
        ]
    },
    {
        "age": 6,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Tocilizumab",
                "day": 1,
                "therapy_index": 15
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            }
        ]
    },
    {
        "age": 2,
        "gender": 2,
        "ARDS": 3,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 1,
                "therapy_index": 4
            },
            {
                "therapy": "Azithromycin",
                "day": 1,
                "therapy_index": 13
            },
            {
                "therapy": "Bronchoscopy",
                "day": 16,
                "therapy_index": 7
            },
            {
                "therapy": "Corticosteroids",
                "day": 18,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 6,
        "gender": 2,
        "ARDS": 3,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Diuretics",
                "day": 1,
                "therapy_index": 9
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Prone positioning",
                "day": 2,
                "therapy_index": 4
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 3,
                "therapy_index": 8
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Corticosteroids",
                "day": 1,
                "therapy_index": 10
            },
            {
                "therapy": "Prone positioning",
                "day": 3,
                "therapy_index": 4
            },
            {
                "therapy": "Diuretics",
                "day": 8,
                "therapy_index": 9
            }
        ]
    },
    {
        "age": 6,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 2,
                "therapy_index": 3
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 2,
                "therapy_index": 17
            },
            {
                "therapy": "Prone positioning",
                "day": 6,
                "therapy_index": 4
            },
            {
                "therapy": "Corticosteroids",
                "day": 16,
                "therapy_index": 10
            },
            {
                "therapy": "Tracheostomy",
                "day": 18,
                "therapy_index": 1
            },
            {
                "therapy": "Inhaled prostacyclin",
                "day": 19,
                "therapy_index": 6
            }
        ]
    },
    {
        "age": 6,
        "gender": 2,
        "ARDS": 3,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Azithromycin",
                "day": 2,
                "therapy_index": 13
            },
            {
                "therapy": "Prone positioning",
                "day": 3,
                "therapy_index": 4
            },
            {
                "therapy": "Diuretics",
                "day": 3,
                "therapy_index": 9
            },
            {
                "therapy": "Inhaled nitric oxide",
                "day": 4,
                "therapy_index": 5
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 5,
                "therapy_index": 8
            },
            {
                "therapy": "Corticosteroids",
                "day": 5,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 4,
        "gender": 1,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 2,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 2,
                "therapy_index": 4
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 2,
                "therapy_index": 8
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Diuretics",
                "day": 1,
                "therapy_index": 9
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 3,
                "therapy_index": 8
            },
            {
                "therapy": "Prone positioning",
                "day": 5,
                "therapy_index": 4
            }
        ]
    },
    {
        "age": 6,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 1,
                "therapy_index": 4
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Tracheostomy",
                "day": 4,
                "therapy_index": 1
            },
            {
                "therapy": "Corticosteroids",
                "day": 6,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 2,
                "therapy_index": 2
            },
            {
                "therapy": "Diuretics",
                "day": 4,
                "therapy_index": 9
            },
            {
                "therapy": "Prone positioning",
                "day": 8,
                "therapy_index": 4
            },
            {
                "therapy": "Corticosteroids",
                "day": 12,
                "therapy_index": 10
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 13,
                "therapy_index": 3
            },
            {
                "therapy": "Tracheostomy",
                "day": 22,
                "therapy_index": 1
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 3,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Diuretics",
                "day": 1,
                "therapy_index": 9
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Prone positioning",
                "day": 2,
                "therapy_index": 4
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 5,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 5,
                "therapy_index": 4
            },
            {
                "therapy": "Inhaled nitric oxide",
                "day": 6,
                "therapy_index": 5
            }
        ]
    },
    {
        "age": 3,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 2,
                "therapy_index": 8
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 4,
                "therapy_index": 2
            },
            {
                "therapy": "Tracheostomy",
                "day": 17,
                "therapy_index": 1
            },
            {
                "therapy": "Therapeutic heparin",
                "day": 19,
                "therapy_index": 11
            },
            {
                "therapy": "Diuretics",
                "day": 30,
                "therapy_index": 9
            }
        ]
    },
    {
        "age": 6,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 2,
                "therapy_index": 17
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 3,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 3,
                "therapy_index": 4
            },
            {
                "therapy": "Anti-Fungal",
                "day": 11,
                "therapy_index": 18
            }
        ]
    },
    {
        "age": 5,
        "gender": 1,
        "ARDS": 1,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 2,
                "therapy_index": 8
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 3,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Corticosteroids",
                "day": 2,
                "therapy_index": 10
            },
            {
                "therapy": "Azithromycin",
                "day": 2,
                "therapy_index": 13
            },
            {
                "therapy": "Tocilizumab",
                "day": 2,
                "therapy_index": 15
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 2,
                "therapy_index": 3
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 2,
                "therapy_index": 8
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Corticosteroids",
                "day": 3,
                "therapy_index": 10
            },
            {
                "therapy": "Tracheostomy",
                "day": 6,
                "therapy_index": 1
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 7,
                "therapy_index": 2
            },
            {
                "therapy": "Therapeutic heparin",
                "day": 17,
                "therapy_index": 11
            }
        ]
    },
    {
        "age": 6,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Diuretics",
                "day": 1,
                "therapy_index": 9
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 2,
                "therapy_index": 2
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 5,
                "therapy_index": 8
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Bronchoscopy",
                "day": 1,
                "therapy_index": 7
            },
            {
                "therapy": "Diuretics",
                "day": 1,
                "therapy_index": 9
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 5,
                "therapy_index": 2
            },
            {
                "therapy": "Prone positioning",
                "day": 8,
                "therapy_index": 4
            },
            {
                "therapy": "Remdesivir",
                "day": 8,
                "therapy_index": 12
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 10,
                "therapy_index": 8
            },
            {
                "therapy": "Therapeutic heparin",
                "day": 10,
                "therapy_index": 11
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Hydroxychloroquine",
                "day": 2,
                "therapy_index": 14
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 8,
                "therapy_index": 2
            },
            {
                "therapy": "Anti-Fungal",
                "day": 8,
                "therapy_index": 18
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 9,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 14,
                "therapy_index": 4
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            }
        ]
    },
    {
        "age": 3,
        "gender": 1,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Corticosteroids",
                "day": 1,
                "therapy_index": 10
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 4,
                "therapy_index": 8
            }
        ]
    },
    {
        "age": 5,
        "gender": 1,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 2,
                "therapy_index": 8
            },
            {
                "therapy": "Corticosteroids",
                "day": 2,
                "therapy_index": 10
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 3,
                "therapy_index": 2
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 3,
                "therapy_index": 2
            },
            {
                "therapy": "Diuretics",
                "day": 3,
                "therapy_index": 9
            },
            {
                "therapy": "Azithromycin",
                "day": 3,
                "therapy_index": 13
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 3,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Corticosteroids",
                "day": 1,
                "therapy_index": 10
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 3,
                "therapy_index": 2
            }
        ]
    },
    {
        "age": 6,
        "gender": 1,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Diuretics",
                "day": 3,
                "therapy_index": 9
            },
            {
                "therapy": "Corticosteroids",
                "day": 4,
                "therapy_index": 10
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 5,
                "therapy_index": 3
            },
            {
                "therapy": "Tracheostomy",
                "day": 7,
                "therapy_index": 1
            }
        ]
    },
    {
        "age": 2,
        "gender": 2,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 2,
                "therapy_index": 3
            },
            {
                "therapy": "Diuretics",
                "day": 4,
                "therapy_index": 9
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 11,
                "therapy_index": 2
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 12,
                "therapy_index": 8
            },
            {
                "therapy": "Corticosteroids",
                "day": 16,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 6,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 3,
                "therapy_index": 3
            },
            {
                "therapy": "Diuretics",
                "day": 4,
                "therapy_index": 9
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 3,
                "therapy_index": 8
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Diuretics",
                "day": 1,
                "therapy_index": 9
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 1,
                "therapy_index": 4
            },
            {
                "therapy": "Azithromycin",
                "day": 2,
                "therapy_index": 13
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 3,
                "therapy_index": 3
            },
            {
                "therapy": "Diuretics",
                "day": 5,
                "therapy_index": 9
            },
            {
                "therapy": "Prone positioning",
                "day": 8,
                "therapy_index": 4
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 9,
                "therapy_index": 8
            },
            {
                "therapy": "Inhaled prostacyclin",
                "day": 10,
                "therapy_index": 6
            },
            {
                "therapy": "Corticosteroids",
                "day": 15,
                "therapy_index": 10
            },
            {
                "therapy": "Therapeutic heparin",
                "day": 19,
                "therapy_index": 11
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Corticosteroids",
                "day": 6,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 3,
                "therapy_index": 2
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 4,
                "therapy_index": 8
            },
            {
                "therapy": "Corticosteroids",
                "day": 4,
                "therapy_index": 10
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 7,
                "therapy_index": 3
            },
            {
                "therapy": "Therapeutic heparin",
                "day": 12,
                "therapy_index": 11
            },
            {
                "therapy": "Diuretics",
                "day": 19,
                "therapy_index": 9
            },
            {
                "therapy": "Inhaled prostacyclin",
                "day": 24,
                "therapy_index": 6
            },
            {
                "therapy": "Anankinra",
                "day": 26,
                "therapy_index": 16
            },
            {
                "therapy": "Tracheostomy",
                "day": 28,
                "therapy_index": 1
            }
        ]
    },
    {
        "age": 4,
        "gender": 1,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 2,
                "therapy_index": 3
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 2,
                "therapy_index": 17
            },
            {
                "therapy": "Tracheostomy",
                "day": 8,
                "therapy_index": 1
            }
        ]
    },
    {
        "age": 6,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 2,
                "therapy_index": 2
            },
            {
                "therapy": "Diuretics",
                "day": 3,
                "therapy_index": 9
            },
            {
                "therapy": "Prone positioning",
                "day": 7,
                "therapy_index": 4
            }
        ]
    },
    {
        "age": 6,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Tracheostomy",
                "day": 2,
                "therapy_index": 1
            },
            {
                "therapy": "Diuretics",
                "day": 3,
                "therapy_index": 9
            },
            {
                "therapy": "Corticosteroids",
                "day": 6,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 3,
        "gender": 2,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Diuretics",
                "day": 3,
                "therapy_index": 9
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 7,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 7,
                "therapy_index": 4
            },
            {
                "therapy": "Inhaled nitric oxide",
                "day": 7,
                "therapy_index": 5
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 8,
                "therapy_index": 8
            },
            {
                "therapy": "Inhaled prostacyclin",
                "day": 9,
                "therapy_index": 6
            },
            {
                "therapy": "Tracheostomy",
                "day": 12,
                "therapy_index": 1
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 12,
                "therapy_index": 2
            },
            {
                "therapy": "Bronchoscopy",
                "day": 12,
                "therapy_index": 7
            },
            {
                "therapy": "Corticosteroids",
                "day": 15,
                "therapy_index": 10
            },
            {
                "therapy": "Therapeutic heparin",
                "day": 15,
                "therapy_index": 11
            },
            {
                "therapy": "Anankinra",
                "day": 20,
                "therapy_index": 16
            }
        ]
    },
    {
        "age": 4,
        "gender": 1,
        "ARDS": 3,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Corticosteroids",
                "day": 2,
                "therapy_index": 10
            },
            {
                "therapy": "Prone positioning",
                "day": 4,
                "therapy_index": 4
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 12,
                "therapy_index": 2
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Diuretics",
                "day": 3,
                "therapy_index": 9
            },
            {
                "therapy": "Prone positioning",
                "day": 6,
                "therapy_index": 4
            },
            {
                "therapy": "Inhaled nitric oxide",
                "day": 6,
                "therapy_index": 5
            },
            {
                "therapy": "Corticosteroids",
                "day": 18,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 5,
        "gender": 1,
        "ARDS": 3,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 2,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 2,
                "therapy_index": 4
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 13,
                "therapy_index": 2
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 1,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 4,
                "therapy_index": 3
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 5,
                "therapy_index": 8
            },
            {
                "therapy": "Diuretics",
                "day": 5,
                "therapy_index": 9
            }
        ]
    },
    {
        "age": 2,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Corticosteroids",
                "day": 2,
                "therapy_index": 10
            },
            {
                "therapy": "Tracheostomy",
                "day": 8,
                "therapy_index": 1
            },
            {
                "therapy": "Diuretics",
                "day": 11,
                "therapy_index": 9
            }
        ]
    },
    {
        "age": 6,
        "gender": 2,
        "ARDS": 1,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Diuretics",
                "day": 1,
                "therapy_index": 9
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 4,
                "therapy_index": 3
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 6,
                "therapy_index": 2
            },
            {
                "therapy": "Prone positioning",
                "day": 7,
                "therapy_index": 4
            },
            {
                "therapy": "Azithromycin",
                "day": 14,
                "therapy_index": 13
            }
        ]
    },
    {
        "age": 5,
        "gender": 1,
        "ARDS": 3,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Diuretics",
                "day": 1,
                "therapy_index": 9
            },
            {
                "therapy": "Corticosteroids",
                "day": 9,
                "therapy_index": 10
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 10,
                "therapy_index": 3
            }
        ]
    },
    {
        "age": 2,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Azithromycin",
                "day": 1,
                "therapy_index": 13
            },
            {
                "therapy": "Hydroxychloroquine",
                "day": 1,
                "therapy_index": 14
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Prone positioning",
                "day": 5,
                "therapy_index": 4
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 1,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 2,
                "therapy_index": 8
            },
            {
                "therapy": "Corticosteroids",
                "day": 2,
                "therapy_index": 10
            },
            {
                "therapy": "Diuretics",
                "day": 5,
                "therapy_index": 9
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 7,
                "therapy_index": 2
            }
        ]
    },
    {
        "age": 5,
        "gender": 1,
        "ARDS": 1,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Prone positioning",
                "day": 1,
                "therapy_index": 4
            },
            {
                "therapy": "Azithromycin",
                "day": 2,
                "therapy_index": 13
            },
            {
                "therapy": "Corticosteroids",
                "day": 3,
                "therapy_index": 10
            },
            {
                "therapy": "Diuretics",
                "day": 4,
                "therapy_index": 9
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 5,
                "therapy_index": 8
            }
        ]
    },
    {
        "age": 1,
        "gender": 2,
        "ARDS": 3,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 3,
                "therapy_index": 2
            },
            {
                "therapy": "Tracheostomy",
                "day": 13,
                "therapy_index": 1
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 13,
                "therapy_index": 3
            }
        ]
    },
    {
        "age": 1,
        "gender": 2,
        "ARDS": 3,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 4,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 7,
                "therapy_index": 3
            },
            {
                "therapy": "Diuretics",
                "day": 8,
                "therapy_index": 9
            },
            {
                "therapy": "Corticosteroids",
                "day": 8,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 3,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 4,
                "therapy_index": 3
            },
            {
                "therapy": "Anti-Fungal",
                "day": 4,
                "therapy_index": 18
            },
            {
                "therapy": "Prone positioning",
                "day": 6,
                "therapy_index": 4
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 7,
                "therapy_index": 2
            },
            {
                "therapy": "Inhaled nitric oxide",
                "day": 10,
                "therapy_index": 5
            },
            {
                "therapy": "Diuretics",
                "day": 14,
                "therapy_index": 9
            },
            {
                "therapy": "Corticosteroids",
                "day": 14,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 6,
        "gender": 2,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Corticosteroids",
                "day": 2,
                "therapy_index": 10
            },
            {
                "therapy": "Diuretics",
                "day": 5,
                "therapy_index": 9
            }
        ]
    },
    {
        "age": 6,
        "gender": 2,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Hydroxychloroquine",
                "day": 1,
                "therapy_index": 14
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Azithromycin",
                "day": 2,
                "therapy_index": 13
            },
            {
                "therapy": "Prone positioning",
                "day": 5,
                "therapy_index": 4
            },
            {
                "therapy": "Bronchoscopy",
                "day": 10,
                "therapy_index": 7
            },
            {
                "therapy": "Corticosteroids",
                "day": 13,
                "therapy_index": 10
            },
            {
                "therapy": "Inhaled nitric oxide",
                "day": 14,
                "therapy_index": 5
            },
            {
                "therapy": "Tracheostomy",
                "day": 24,
                "therapy_index": 1
            }
        ]
    },
    {
        "age": 6,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Prone positioning",
                "day": 1,
                "therapy_index": 4
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 4,
                "therapy_index": 8
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 6,
                "therapy_index": 3
            },
            {
                "therapy": "Corticosteroids",
                "day": 9,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 4,
        "gender": 1,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 2,
                "therapy_index": 3
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Remdesivir",
                "day": 2,
                "therapy_index": 12
            },
            {
                "therapy": "Prone positioning",
                "day": 8,
                "therapy_index": 4
            },
            {
                "therapy": "Inhaled nitric oxide",
                "day": 10,
                "therapy_index": 5
            },
            {
                "therapy": "Corticosteroids",
                "day": 10,
                "therapy_index": 10
            },
            {
                "therapy": "Tracheostomy",
                "day": 18,
                "therapy_index": 1
            }
        ]
    },
    {
        "age": 5,
        "gender": 1,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 1,
                "therapy_index": 8
            },
            {
                "therapy": "Prone positioning",
                "day": 2,
                "therapy_index": 4
            },
            {
                "therapy": "Diuretics",
                "day": 7,
                "therapy_index": 9
            },
            {
                "therapy": "Tracheostomy",
                "day": 13,
                "therapy_index": 1
            },
            {
                "therapy": "Corticosteroids",
                "day": 22,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 1,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 1,
                "therapy_index": 4
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 1,
                "therapy_index": 8
            },
            {
                "therapy": "Hydroxychloroquine",
                "day": 1,
                "therapy_index": 14
            },
            {
                "therapy": "Azithromycin",
                "day": 2,
                "therapy_index": 13
            },
            {
                "therapy": "Inhaled nitric oxide",
                "day": 5,
                "therapy_index": 5
            }
        ]
    },
    {
        "age": 6,
        "gender": 2,
        "ARDS": 3,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 2,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 2,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 2,
                "therapy_index": 4
            },
            {
                "therapy": "Inhaled nitric oxide",
                "day": 2,
                "therapy_index": 5
            },
            {
                "therapy": "Azithromycin",
                "day": 2,
                "therapy_index": 13
            },
            {
                "therapy": "Diuretics",
                "day": 5,
                "therapy_index": 9
            },
            {
                "therapy": "Corticosteroids",
                "day": 9,
                "therapy_index": 10
            },
            {
                "therapy": "Bronchoscopy",
                "day": 14,
                "therapy_index": 7
            },
            {
                "therapy": "Tracheostomy",
                "day": 16,
                "therapy_index": 1
            }
        ]
    },
    {
        "age": 5,
        "gender": 1,
        "ARDS": 3,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 2,
                "therapy_index": 2
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Corticosteroids",
                "day": 2,
                "therapy_index": 10
            },
            {
                "therapy": "Hydroxychloroquine",
                "day": 2,
                "therapy_index": 14
            },
            {
                "therapy": "Tocilizumab",
                "day": 8,
                "therapy_index": 15
            }
        ]
    },
    {
        "age": 4,
        "gender": 1,
        "ARDS": 3,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 2,
                "therapy_index": 3
            },
            {
                "therapy": "Corticosteroids",
                "day": 8,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 4,
        "gender": 1,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 1,
                "therapy_index": 4
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 2,
                "therapy_index": 2
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Azithromycin",
                "day": 2,
                "therapy_index": 13
            },
            {
                "therapy": "Inhaled nitric oxide",
                "day": 5,
                "therapy_index": 5
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 6,
                "therapy_index": 8
            },
            {
                "therapy": "Corticosteroids",
                "day": 11,
                "therapy_index": 10
            },
            {
                "therapy": "Tracheostomy",
                "day": 19,
                "therapy_index": 1
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Diuretics",
                "day": 5,
                "therapy_index": 9
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 6,
                "therapy_index": 2
            },
            {
                "therapy": "Tocilizumab",
                "day": 8,
                "therapy_index": 15
            },
            {
                "therapy": "Prone positioning",
                "day": 13,
                "therapy_index": 4
            },
            {
                "therapy": "Tracheostomy",
                "day": 24,
                "therapy_index": 1
            }
        ]
    },
    {
        "age": 4,
        "gender": 1,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Diuretics",
                "day": 3,
                "therapy_index": 9
            },
            {
                "therapy": "Corticosteroids",
                "day": 5,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 6,
                "therapy_index": 2
            },
            {
                "therapy": "Diuretics",
                "day": 12,
                "therapy_index": 9
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 14,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 14,
                "therapy_index": 4
            },
            {
                "therapy": "Inhaled nitric oxide",
                "day": 22,
                "therapy_index": 5
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 3,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 1,
                "therapy_index": 4
            },
            {
                "therapy": "Inhaled nitric oxide",
                "day": 1,
                "therapy_index": 5
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Azithromycin",
                "day": 2,
                "therapy_index": 13
            },
            {
                "therapy": "Hydroxychloroquine",
                "day": 2,
                "therapy_index": 14
            },
            {
                "therapy": "Corticosteroids",
                "day": 8,
                "therapy_index": 10
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 18,
                "therapy_index": 8
            },
            {
                "therapy": "Bronchoscopy",
                "day": 29,
                "therapy_index": 7
            }
        ]
    },
    {
        "age": 4,
        "gender": 1,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 3,
                "therapy_index": 3
            },
            {
                "therapy": "Corticosteroids",
                "day": 16,
                "therapy_index": 10
            },
            {
                "therapy": "Tracheostomy",
                "day": 17,
                "therapy_index": 1
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 2,
                "therapy_index": 3
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 2,
                "therapy_index": 17
            },
            {
                "therapy": "Prone positioning",
                "day": 3,
                "therapy_index": 4
            },
            {
                "therapy": "Diuretics",
                "day": 3,
                "therapy_index": 9
            },
            {
                "therapy": "Corticosteroids",
                "day": 7,
                "therapy_index": 10
            },
            {
                "therapy": "Anti-Fungal",
                "day": 7,
                "therapy_index": 18
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 8,
                "therapy_index": 8
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 3,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 1,
                "therapy_index": 4
            },
            {
                "therapy": "Diuretics",
                "day": 4,
                "therapy_index": 9
            },
            {
                "therapy": "Tocilizumab",
                "day": 13,
                "therapy_index": 15
            },
            {
                "therapy": "Corticosteroids",
                "day": 18,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Diuretics",
                "day": 3,
                "therapy_index": 9
            }
        ]
    },
    {
        "age": 3,
        "gender": 2,
        "ARDS": 3,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 2,
                "therapy_index": 2
            },
            {
                "therapy": "Corticosteroids",
                "day": 4,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Diuretics",
                "day": 1,
                "therapy_index": 9
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 2,
                "therapy_index": 3
            },
            {
                "therapy": "Azithromycin",
                "day": 3,
                "therapy_index": 13
            },
            {
                "therapy": "Hydroxychloroquine",
                "day": 3,
                "therapy_index": 14
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            }
        ]
    },
    {
        "age": 3,
        "gender": 1,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 1,
                "therapy_index": 4
            },
            {
                "therapy": "Corticosteroids",
                "day": 12,
                "therapy_index": 10
            },
            {
                "therapy": "Diuretics",
                "day": 14,
                "therapy_index": 9
            }
        ]
    },
    {
        "age": 3,
        "gender": 1,
        "ARDS": 3,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 3,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 2,
                "therapy_index": 2
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Prone positioning",
                "day": 3,
                "therapy_index": 4
            },
            {
                "therapy": "Anti-Fungal",
                "day": 14,
                "therapy_index": 18
            },
            {
                "therapy": "Corticosteroids",
                "day": 16,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 1,
        "gender": 2,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Anti-Fungal",
                "day": 1,
                "therapy_index": 18
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Prone positioning",
                "day": 5,
                "therapy_index": 4
            },
            {
                "therapy": "Tracheostomy",
                "day": 9,
                "therapy_index": 1
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            }
        ]
    },
    {
        "age": 6,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 5,
                "therapy_index": 2
            },
            {
                "therapy": "Corticosteroids",
                "day": 5,
                "therapy_index": 10
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 11,
                "therapy_index": 3
            }
        ]
    },
    {
        "age": 6,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            }
        ]
    },
    {
        "age": 3,
        "gender": 1,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 3,
                "therapy_index": 2
            },
            {
                "therapy": "Diuretics",
                "day": 3,
                "therapy_index": 9
            },
            {
                "therapy": "Corticosteroids",
                "day": 9,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 5,
        "gender": 1,
        "ARDS": 3,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 4,
                "therapy_index": 2
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 6,
                "therapy_index": 8
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Corticosteroids",
                "day": 2,
                "therapy_index": 10
            },
            {
                "therapy": "Diuretics",
                "day": 6,
                "therapy_index": 9
            }
        ]
    },
    {
        "age": 3,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 1,
                "therapy_index": 4
            },
            {
                "therapy": "Diuretics",
                "day": 1,
                "therapy_index": 9
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 1,
                "therapy_index": 8
            },
            {
                "therapy": "Corticosteroids",
                "day": 2,
                "therapy_index": 10
            },
            {
                "therapy": "Diuretics",
                "day": 3,
                "therapy_index": 9
            }
        ]
    },
    {
        "age": 6,
        "gender": 2,
        "ARDS": 3,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Diuretics",
                "day": 1,
                "therapy_index": 9
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 2,
                "therapy_index": 8
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Diuretics",
                "day": 6,
                "therapy_index": 9
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 8,
                "therapy_index": 8
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 14,
                "therapy_index": 3
            },
            {
                "therapy": "Therapeutic heparin",
                "day": 14,
                "therapy_index": 11
            },
            {
                "therapy": "Inhaled prostacyclin",
                "day": 15,
                "therapy_index": 6
            },
            {
                "therapy": "Corticosteroids",
                "day": 15,
                "therapy_index": 10
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 16,
                "therapy_index": 2
            },
            {
                "therapy": "Prone positioning",
                "day": 17,
                "therapy_index": 4
            }
        ]
    },
    {
        "age": 3,
        "gender": 2,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Corticosteroids",
                "day": 1,
                "therapy_index": 10
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 4,
                "therapy_index": 3
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 6,
                "therapy_index": 8
            },
            {
                "therapy": "Corticosteroids",
                "day": 13,
                "therapy_index": 10
            },
            {
                "therapy": "Tracheostomy",
                "day": 16,
                "therapy_index": 1
            },
            {
                "therapy": "Bronchoscopy",
                "day": 16,
                "therapy_index": 7
            }
        ]
    },
    {
        "age": 1,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Inhaled prostacyclin",
                "day": 1,
                "therapy_index": 6
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Diuretics",
                "day": 10,
                "therapy_index": 9
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 2,
                "therapy_index": 2
            },
            {
                "therapy": "Diuretics",
                "day": 8,
                "therapy_index": 9
            },
            {
                "therapy": "Corticosteroids",
                "day": 9,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 3,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Azithromycin",
                "day": 3,
                "therapy_index": 13
            },
            {
                "therapy": "Hydroxychloroquine",
                "day": 3,
                "therapy_index": 14
            },
            {
                "therapy": "Prone positioning",
                "day": 5,
                "therapy_index": 4
            },
            {
                "therapy": "Inhaled nitric oxide",
                "day": 5,
                "therapy_index": 5
            },
            {
                "therapy": "Bronchoscopy",
                "day": 11,
                "therapy_index": 7
            },
            {
                "therapy": "Tracheostomy",
                "day": 16,
                "therapy_index": 1
            }
        ]
    },
    {
        "age": 5,
        "gender": 1,
        "ARDS": 3,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 1,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 4,
                "therapy_index": 3
            },
            {
                "therapy": "Corticosteroids",
                "day": 4,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 6,
        "gender": 2,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Corticosteroids",
                "day": 7,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 5,
        "gender": 1,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Corticosteroids",
                "day": 2,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 5,
        "gender": 1,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 2,
                "therapy_index": 2
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 8,
                "therapy_index": 3
            },
            {
                "therapy": "Bronchoscopy",
                "day": 12,
                "therapy_index": 7
            },
            {
                "therapy": "Corticosteroids",
                "day": 12,
                "therapy_index": 10
            },
            {
                "therapy": "Inhaled prostacyclin",
                "day": 14,
                "therapy_index": 6
            },
            {
                "therapy": "Prone positioning",
                "day": 15,
                "therapy_index": 4
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 19,
                "therapy_index": 8
            },
            {
                "therapy": "Therapeutic heparin",
                "day": 20,
                "therapy_index": 11
            },
            {
                "therapy": "Tracheostomy",
                "day": 24,
                "therapy_index": 1
            }
        ]
    },
    {
        "age": 4,
        "gender": 1,
        "ARDS": 3,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Corticosteroids",
                "day": 19,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 4,
        "gender": 1,
        "ARDS": 3,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 2,
                "therapy_index": 3
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Prone positioning",
                "day": 3,
                "therapy_index": 4
            },
            {
                "therapy": "Tracheostomy",
                "day": 10,
                "therapy_index": 1
            }
        ]
    },
    {
        "age": 6,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 5,
                "therapy_index": 3
            },
            {
                "therapy": "Diuretics",
                "day": 5,
                "therapy_index": 9
            }
        ]
    },
    {
        "age": 2,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 4,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 4,
                "therapy_index": 4
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 1,
                "therapy_index": 8
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 4,
                "therapy_index": 3
            }
        ]
    },
    {
        "age": 6,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Diuretics",
                "day": 1,
                "therapy_index": 9
            },
            {
                "therapy": "Corticosteroids",
                "day": 1,
                "therapy_index": 10
            },
            {
                "therapy": "Azithromycin",
                "day": 1,
                "therapy_index": 13
            },
            {
                "therapy": "Hydroxychloroquine",
                "day": 1,
                "therapy_index": 14
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 2,
                "therapy_index": 8
            },
            {
                "therapy": "Prone positioning",
                "day": 3,
                "therapy_index": 4
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 3,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 2,
                "therapy_index": 2
            },
            {
                "therapy": "Corticosteroids",
                "day": 2,
                "therapy_index": 10
            },
            {
                "therapy": "Diuretics",
                "day": 4,
                "therapy_index": 9
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 5,
                "therapy_index": 8
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 3,
                "therapy_index": 2
            },
            {
                "therapy": "Prone positioning",
                "day": 8,
                "therapy_index": 4
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 2,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 2,
                "therapy_index": 4
            },
            {
                "therapy": "Diuretics",
                "day": 3,
                "therapy_index": 9
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 6,
                "therapy_index": 2
            },
            {
                "therapy": "Inhaled nitric oxide",
                "day": 7,
                "therapy_index": 5
            },
            {
                "therapy": "Inhaled prostacyclin",
                "day": 7,
                "therapy_index": 6
            },
            {
                "therapy": "Tracheostomy",
                "day": 20,
                "therapy_index": 1
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Diuretics",
                "day": 6,
                "therapy_index": 9
            },
            {
                "therapy": "Corticosteroids",
                "day": 13,
                "therapy_index": 10
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 15,
                "therapy_index": 8
            }
        ]
    },
    {
        "age": 6,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 2,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 3,
                "therapy_index": 3
            },
            {
                "therapy": "Diuretics",
                "day": 4,
                "therapy_index": 9
            }
        ]
    },
    {
        "age": 4,
        "gender": 1,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Diuretics",
                "day": 4,
                "therapy_index": 9
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 7,
                "therapy_index": 2
            },
            {
                "therapy": "Corticosteroids",
                "day": 10,
                "therapy_index": 10
            },
            {
                "therapy": "Prone positioning",
                "day": 12,
                "therapy_index": 4
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Diuretics",
                "day": 3,
                "therapy_index": 9
            },
            {
                "therapy": "Prone positioning",
                "day": 8,
                "therapy_index": 4
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 16,
                "therapy_index": 8
            },
            {
                "therapy": "Bronchoscopy",
                "day": 17,
                "therapy_index": 7
            },
            {
                "therapy": "Therapeutic heparin",
                "day": 17,
                "therapy_index": 11
            }
        ]
    },
    {
        "age": 5,
        "gender": 1,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Diuretics",
                "day": 4,
                "therapy_index": 9
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 6,
                "therapy_index": 3
            },
            {
                "therapy": "Corticosteroids",
                "day": 6,
                "therapy_index": 10
            },
            {
                "therapy": "Inhaled prostacyclin",
                "day": 7,
                "therapy_index": 6
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 7,
                "therapy_index": 8
            },
            {
                "therapy": "Therapeutic heparin",
                "day": 7,
                "therapy_index": 11
            },
            {
                "therapy": "Tracheostomy",
                "day": 21,
                "therapy_index": 1
            }
        ]
    },
    {
        "age": 3,
        "gender": 1,
        "ARDS": 3,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 2,
                "therapy_index": 2
            },
            {
                "therapy": "Corticosteroids",
                "day": 2,
                "therapy_index": 10
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 3,
                "therapy_index": 3
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 3,
                "therapy_index": 8
            },
            {
                "therapy": "Prone positioning",
                "day": 5,
                "therapy_index": 4
            },
            {
                "therapy": "Tracheostomy",
                "day": 11,
                "therapy_index": 1
            },
            {
                "therapy": "Bronchoscopy",
                "day": 18,
                "therapy_index": 7
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 3,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Tracheostomy",
                "day": 1,
                "therapy_index": 1
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Corticosteroids",
                "day": 2,
                "therapy_index": 10
            },
            {
                "therapy": "Inhaled prostacyclin",
                "day": 3,
                "therapy_index": 6
            },
            {
                "therapy": "Diuretics",
                "day": 3,
                "therapy_index": 9
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 6,
                "therapy_index": 2
            },
            {
                "therapy": "Bronchoscopy",
                "day": 8,
                "therapy_index": 7
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Diuretics",
                "day": 6,
                "therapy_index": 9
            },
            {
                "therapy": "Corticosteroids",
                "day": 7,
                "therapy_index": 10
            },
            {
                "therapy": "Therapeutic heparin",
                "day": 7,
                "therapy_index": 11
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 11,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 11,
                "therapy_index": 4
            },
            {
                "therapy": "Inhaled prostacyclin",
                "day": 11,
                "therapy_index": 6
            },
            {
                "therapy": "Inhaled nitric oxide",
                "day": 12,
                "therapy_index": 5
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 16,
                "therapy_index": 8
            }
        ]
    },
    {
        "age": 6,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 3,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 2,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 4,
                "therapy_index": 3
            },
            {
                "therapy": "Diuretics",
                "day": 4,
                "therapy_index": 9
            },
            {
                "therapy": "Inhaled prostacyclin",
                "day": 6,
                "therapy_index": 6
            },
            {
                "therapy": "Prone positioning",
                "day": 7,
                "therapy_index": 4
            },
            {
                "therapy": "Corticosteroids",
                "day": 7,
                "therapy_index": 10
            },
            {
                "therapy": "Inhaled nitric oxide",
                "day": 9,
                "therapy_index": 5
            }
        ]
    },
    {
        "age": 6,
        "gender": 1,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Diuretics",
                "day": 3,
                "therapy_index": 9
            },
            {
                "therapy": "Corticosteroids",
                "day": 9,
                "therapy_index": 10
            },
            {
                "therapy": "Tracheostomy",
                "day": 12,
                "therapy_index": 1
            },
            {
                "therapy": "Inhaled prostacyclin",
                "day": 21,
                "therapy_index": 6
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Diuretics",
                "day": 1,
                "therapy_index": 9
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Bronchoscopy",
                "day": 1,
                "therapy_index": 7
            },
            {
                "therapy": "Diuretics",
                "day": 3,
                "therapy_index": 9
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Azithromycin",
                "day": 2,
                "therapy_index": 13
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 2,
                "therapy_index": 17
            },
            {
                "therapy": "Hydroxychloroquine",
                "day": 4,
                "therapy_index": 14
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 5,
                "therapy_index": 2
            },
            {
                "therapy": "Prone positioning",
                "day": 5,
                "therapy_index": 4
            },
            {
                "therapy": "Bronchoscopy",
                "day": 6,
                "therapy_index": 7
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 11,
                "therapy_index": 8
            },
            {
                "therapy": "Corticosteroids",
                "day": 11,
                "therapy_index": 10
            },
            {
                "therapy": "Therapeutic heparin",
                "day": 11,
                "therapy_index": 11
            },
            {
                "therapy": "Tracheostomy",
                "day": 16,
                "therapy_index": 1
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Diuretics",
                "day": 1,
                "therapy_index": 9
            },
            {
                "therapy": "Hydroxychloroquine",
                "day": 1,
                "therapy_index": 14
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 2,
                "therapy_index": 3
            },
            {
                "therapy": "Azithromycin",
                "day": 2,
                "therapy_index": 13
            },
            {
                "therapy": "Prone positioning",
                "day": 3,
                "therapy_index": 4
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 7,
                "therapy_index": 8
            },
            {
                "therapy": "Corticosteroids",
                "day": 17,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 6,
        "gender": 1,
        "ARDS": 3,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Prone positioning",
                "day": 2,
                "therapy_index": 4
            },
            {
                "therapy": "Inhaled nitric oxide",
                "day": 8,
                "therapy_index": 5
            }
        ]
    },
    {
        "age": 2,
        "gender": 1,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Diuretics",
                "day": 7,
                "therapy_index": 9
            },
            {
                "therapy": "Corticosteroids",
                "day": 7,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 1,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Prone positioning",
                "day": 16,
                "therapy_index": 4
            },
            {
                "therapy": "Inhaled nitric oxide",
                "day": 16,
                "therapy_index": 5
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 3,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Corticosteroids",
                "day": 1,
                "therapy_index": 10
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 2,
                "therapy_index": 8
            }
        ]
    },
    {
        "age": 5,
        "gender": 1,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Corticosteroids",
                "day": 1,
                "therapy_index": 10
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 2,
                "therapy_index": 3
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 3,
                "therapy_index": 8
            }
        ]
    },
    {
        "age": 5,
        "gender": 1,
        "ARDS": 3,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Prone positioning",
                "day": 2,
                "therapy_index": 4
            }
        ]
    },
    {
        "age": 3,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 4,
                "therapy_index": 8
            },
            {
                "therapy": "Diuretics",
                "day": 4,
                "therapy_index": 9
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 3,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Corticosteroids",
                "day": 1,
                "therapy_index": 10
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 2,
                "therapy_index": 3
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Inhaled prostacyclin",
                "day": 3,
                "therapy_index": 6
            },
            {
                "therapy": "Prone positioning",
                "day": 5,
                "therapy_index": 4
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Diuretics",
                "day": 1,
                "therapy_index": 9
            },
            {
                "therapy": "Therapeutic heparin",
                "day": 1,
                "therapy_index": 11
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Azithromycin",
                "day": 2,
                "therapy_index": 13
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 3,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 4,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 4,
                "therapy_index": 4
            },
            {
                "therapy": "Inhaled nitric oxide",
                "day": 8,
                "therapy_index": 5
            },
            {
                "therapy": "Tracheostomy",
                "day": 9,
                "therapy_index": 1
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Diuretics",
                "day": 3,
                "therapy_index": 9
            }
        ]
    },
    {
        "age": 4,
        "gender": 1,
        "ARDS": 3,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Corticosteroids",
                "day": 1,
                "therapy_index": 10
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 2,
                "therapy_index": 3
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 5,
                "therapy_index": 2
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 13,
                "therapy_index": 8
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 2,
                "therapy_index": 8
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 2,
                "therapy_index": 17
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 10,
                "therapy_index": 2
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 3,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Corticosteroids",
                "day": 3,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            }
        ]
    },
    {
        "age": 3,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 2,
                "therapy_index": 3
            },
            {
                "therapy": "Corticosteroids",
                "day": 2,
                "therapy_index": 10
            },
            {
                "therapy": "Diuretics",
                "day": 3,
                "therapy_index": 9
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 6,
                "therapy_index": 8
            }
        ]
    },
    {
        "age": 4,
        "gender": 1,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 2,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 2,
                "therapy_index": 3
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 2,
                "therapy_index": 17
            },
            {
                "therapy": "Prone positioning",
                "day": 7,
                "therapy_index": 4
            }
        ]
    },
    {
        "age": 3,
        "gender": 2,
        "ARDS": 1,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Diuretics",
                "day": 4,
                "therapy_index": 9
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 15,
                "therapy_index": 3
            },
            {
                "therapy": "Corticosteroids",
                "day": 38,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 4,
        "gender": 1,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Diuretics",
                "day": 1,
                "therapy_index": 9
            },
            {
                "therapy": "Corticosteroids",
                "day": 3,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 3,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Hydroxychloroquine",
                "day": 2,
                "therapy_index": 14
            },
            {
                "therapy": "Azithromycin",
                "day": 3,
                "therapy_index": 13
            },
            {
                "therapy": "Prone positioning",
                "day": 4,
                "therapy_index": 4
            },
            {
                "therapy": "Inhaled nitric oxide",
                "day": 9,
                "therapy_index": 5
            },
            {
                "therapy": "Corticosteroids",
                "day": 19,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 3,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            }
        ]
    },
    {
        "age": 5,
        "gender": 1,
        "ARDS": 3,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Prone positioning",
                "day": 1,
                "therapy_index": 4
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Diuretics",
                "day": 3,
                "therapy_index": 9
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 4,
                "therapy_index": 3
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            }
        ]
    },
    {
        "age": 3,
        "gender": 1,
        "ARDS": 3,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Azithromycin",
                "day": 1,
                "therapy_index": 13
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 2,
                "therapy_index": 2
            },
            {
                "therapy": "Inhaled prostacyclin",
                "day": 2,
                "therapy_index": 6
            },
            {
                "therapy": "Corticosteroids",
                "day": 2,
                "therapy_index": 10
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 3,
                "therapy_index": 3
            },
            {
                "therapy": "Diuretics",
                "day": 3,
                "therapy_index": 9
            },
            {
                "therapy": "Anankinra",
                "day": 3,
                "therapy_index": 16
            },
            {
                "therapy": "Tracheostomy",
                "day": 12,
                "therapy_index": 1
            }
        ]
    },
    {
        "age": 2,
        "gender": 1,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Diuretics",
                "day": 3,
                "therapy_index": 9
            }
        ]
    },
    {
        "age": 6,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 2,
                "therapy_index": 8
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Prone positioning",
                "day": 1,
                "therapy_index": 4
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 2,
                "therapy_index": 3
            },
            {
                "therapy": "Diuretics",
                "day": 3,
                "therapy_index": 9
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 3,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Inhaled nitric oxide",
                "day": 1,
                "therapy_index": 5
            },
            {
                "therapy": "Prone positioning",
                "day": 2,
                "therapy_index": 4
            },
            {
                "therapy": "Azithromycin",
                "day": 2,
                "therapy_index": 13
            },
            {
                "therapy": "Diuretics",
                "day": 3,
                "therapy_index": 9
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 6,
                "therapy_index": 8
            },
            {
                "therapy": "Corticosteroids",
                "day": 6,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 3,
                "therapy_index": 2
            },
            {
                "therapy": "Corticosteroids",
                "day": 11,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 6,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 2,
                "therapy_index": 2
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Hydroxychloroquine",
                "day": 2,
                "therapy_index": 14
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 3,
                "therapy_index": 3
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 17,
                "therapy_index": 8
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 2,
                "therapy_index": 2
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 2,
                "therapy_index": 8
            },
            {
                "therapy": "Corticosteroids",
                "day": 2,
                "therapy_index": 10
            },
            {
                "therapy": "Therapeutic heparin",
                "day": 2,
                "therapy_index": 11
            },
            {
                "therapy": "Diuretics",
                "day": 7,
                "therapy_index": 9
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 11,
                "therapy_index": 3
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 2,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 2,
                "therapy_index": 3
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Inhaled prostacyclin",
                "day": 3,
                "therapy_index": 6
            },
            {
                "therapy": "Corticosteroids",
                "day": 3,
                "therapy_index": 10
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 4,
                "therapy_index": 8
            },
            {
                "therapy": "Tracheostomy",
                "day": 16,
                "therapy_index": 1
            }
        ]
    },
    {
        "age": 2,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 2,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 2,
                "therapy_index": 4
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 3,
                "therapy_index": 2
            },
            {
                "therapy": "Diuretics",
                "day": 5,
                "therapy_index": 9
            },
            {
                "therapy": "Corticosteroids",
                "day": 12,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Diuretics",
                "day": 4,
                "therapy_index": 9
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 6,
                "therapy_index": 3
            }
        ]
    },
    {
        "age": 4,
        "gender": 1,
        "ARDS": 3,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 7,
                "therapy_index": 8
            },
            {
                "therapy": "Corticosteroids",
                "day": 11,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 6,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 1,
                "therapy_index": 4
            },
            {
                "therapy": "Diuretics",
                "day": 1,
                "therapy_index": 9
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Tracheostomy",
                "day": 3,
                "therapy_index": 1
            },
            {
                "therapy": "Corticosteroids",
                "day": 4,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 3,
                "therapy_index": 2
            },
            {
                "therapy": "Diuretics",
                "day": 3,
                "therapy_index": 9
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 6,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 6,
                "therapy_index": 4
            },
            {
                "therapy": "Corticosteroids",
                "day": 6,
                "therapy_index": 10
            },
            {
                "therapy": "Inhaled prostacyclin",
                "day": 19,
                "therapy_index": 6
            },
            {
                "therapy": "Anankinra",
                "day": 19,
                "therapy_index": 16
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 2,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 2,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 2,
                "therapy_index": 4
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 2,
                "therapy_index": 8
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 2,
                "therapy_index": 17
            },
            {
                "therapy": "Anti-Fungal",
                "day": 12,
                "therapy_index": 18
            },
            {
                "therapy": "Tracheostomy",
                "day": 14,
                "therapy_index": 1
            }
        ]
    },
    {
        "age": 3,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Prone positioning",
                "day": 6,
                "therapy_index": 4
            },
            {
                "therapy": "Diuretics",
                "day": 9,
                "therapy_index": 9
            },
            {
                "therapy": "Tracheostomy",
                "day": 15,
                "therapy_index": 1
            },
            {
                "therapy": "Anti-Fungal",
                "day": 20,
                "therapy_index": 18
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 1,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Azithromycin",
                "day": 2,
                "therapy_index": 13
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 4,
                "therapy_index": 3
            },
            {
                "therapy": "Diuretics",
                "day": 4,
                "therapy_index": 9
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 9,
                "therapy_index": 8
            }
        ]
    },
    {
        "age": 3,
        "gender": 2,
        "ARDS": 3,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 2,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 2,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 4,
                "therapy_index": 4
            },
            {
                "therapy": "Diuretics",
                "day": 4,
                "therapy_index": 9
            },
            {
                "therapy": "Inhaled nitric oxide",
                "day": 5,
                "therapy_index": 5
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 5,
                "therapy_index": 8
            },
            {
                "therapy": "Corticosteroids",
                "day": 5,
                "therapy_index": 10
            },
            {
                "therapy": "Therapeutic heparin",
                "day": 5,
                "therapy_index": 11
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Diuretics",
                "day": 8,
                "therapy_index": 9
            },
            {
                "therapy": "Corticosteroids",
                "day": 10,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Azithromycin",
                "day": 1,
                "therapy_index": 13
            },
            {
                "therapy": "Hydroxychloroquine",
                "day": 1,
                "therapy_index": 14
            },
            {
                "therapy": "Prone positioning",
                "day": 2,
                "therapy_index": 4
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 5,
                "therapy_index": 3
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 7,
                "therapy_index": 8
            },
            {
                "therapy": "Corticosteroids",
                "day": 9,
                "therapy_index": 10
            },
            {
                "therapy": "Tracheostomy",
                "day": 28,
                "therapy_index": 1
            },
            {
                "therapy": "Inhaled nitric oxide",
                "day": 31,
                "therapy_index": 5
            },
            {
                "therapy": "Bronchoscopy",
                "day": 34,
                "therapy_index": 7
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Prone positioning",
                "day": 3,
                "therapy_index": 4
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 3,
                "therapy_index": 8
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 4,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 4,
                "therapy_index": 3
            },
            {
                "therapy": "Corticosteroids",
                "day": 5,
                "therapy_index": 10
            },
            {
                "therapy": "Therapeutic heparin",
                "day": 5,
                "therapy_index": 11
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 5,
                "therapy_index": 2
            }
        ]
    },
    {
        "age": 6,
        "gender": 2,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 2,
                "therapy_index": 17
            }
        ]
    },
    {
        "age": 3,
        "gender": 1,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Bronchoscopy",
                "day": 1,
                "therapy_index": 7
            },
            {
                "therapy": "Corticosteroids",
                "day": 1,
                "therapy_index": 10
            },
            {
                "therapy": "Therapeutic heparin",
                "day": 2,
                "therapy_index": 11
            },
            {
                "therapy": "Diuretics",
                "day": 4,
                "therapy_index": 9
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 5,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 5,
                "therapy_index": 3
            },
            {
                "therapy": "Inhaled nitric oxide",
                "day": 5,
                "therapy_index": 5
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 5,
                "therapy_index": 8
            },
            {
                "therapy": "Prone positioning",
                "day": 9,
                "therapy_index": 4
            },
            {
                "therapy": "Tracheostomy",
                "day": 12,
                "therapy_index": 1
            }
        ]
    },
    {
        "age": 3,
        "gender": 2,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            }
        ]
    },
    {
        "age": 5,
        "gender": 1,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Diuretics",
                "day": 4,
                "therapy_index": 9
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 3,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 2,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 4,
                "therapy_index": 4
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 12,
                "therapy_index": 8
            },
            {
                "therapy": "Corticosteroids",
                "day": 13,
                "therapy_index": 10
            },
            {
                "therapy": "Bronchoscopy",
                "day": 14,
                "therapy_index": 7
            },
            {
                "therapy": "Tracheostomy",
                "day": 15,
                "therapy_index": 1
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 2,
                "therapy_index": 3
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 3,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 1,
                "therapy_index": 4
            },
            {
                "therapy": "Hydroxychloroquine",
                "day": 1,
                "therapy_index": 14
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Azithromycin",
                "day": 2,
                "therapy_index": 13
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 6,
                "therapy_index": 2
            },
            {
                "therapy": "Corticosteroids",
                "day": 17,
                "therapy_index": 10
            },
            {
                "therapy": "Tracheostomy",
                "day": 21,
                "therapy_index": 1
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 3,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 1,
                "therapy_index": 4
            },
            {
                "therapy": "Inhaled nitric oxide",
                "day": 1,
                "therapy_index": 5
            },
            {
                "therapy": "Hydroxychloroquine",
                "day": 1,
                "therapy_index": 14
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 2,
                "therapy_index": 2
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 2,
                "therapy_index": 8
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Azithromycin",
                "day": 2,
                "therapy_index": 13
            },
            {
                "therapy": "Corticosteroids",
                "day": 4,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 4,
        "gender": 1,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Tracheostomy",
                "day": 12,
                "therapy_index": 1
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 12,
                "therapy_index": 3
            },
            {
                "therapy": "Diuretics",
                "day": 12,
                "therapy_index": 9
            },
            {
                "therapy": "Corticosteroids",
                "day": 26,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 3,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 2,
                "therapy_index": 2
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Azithromycin",
                "day": 2,
                "therapy_index": 13
            },
            {
                "therapy": "Corticosteroids",
                "day": 12,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            }
        ]
    },
    {
        "age": 6,
        "gender": 1,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Diuretics",
                "day": 3,
                "therapy_index": 9
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 6,
                "therapy_index": 3
            },
            {
                "therapy": "Corticosteroids",
                "day": 31,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 6,
        "gender": 2,
        "ARDS": 3,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 3,
                "therapy_index": 3
            },
            {
                "therapy": "Diuretics",
                "day": 3,
                "therapy_index": 9
            },
            {
                "therapy": "Prone positioning",
                "day": 5,
                "therapy_index": 4
            },
            {
                "therapy": "Corticosteroids",
                "day": 5,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 3,
        "gender": 2,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Corticosteroids",
                "day": 12,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 6,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Azithromycin",
                "day": 2,
                "therapy_index": 13
            },
            {
                "therapy": "Hydroxychloroquine",
                "day": 2,
                "therapy_index": 14
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 3,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 3,
                "therapy_index": 4
            },
            {
                "therapy": "Corticosteroids",
                "day": 3,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 4,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 4,
                "therapy_index": 3
            },
            {
                "therapy": "Corticosteroids",
                "day": 19,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 5,
        "gender": 1,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 4,
                "therapy_index": 3
            },
            {
                "therapy": "Tracheostomy",
                "day": 8,
                "therapy_index": 1
            }
        ]
    },
    {
        "age": 3,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Diuretics",
                "day": 3,
                "therapy_index": 9
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 5,
                "therapy_index": 8
            },
            {
                "therapy": "Therapeutic heparin",
                "day": 5,
                "therapy_index": 11
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 8,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 8,
                "therapy_index": 4
            }
        ]
    },
    {
        "age": 5,
        "gender": 1,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 1,
                "therapy_index": 4
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Azithromycin",
                "day": 2,
                "therapy_index": 13
            },
            {
                "therapy": "Hydroxychloroquine",
                "day": 2,
                "therapy_index": 14
            },
            {
                "therapy": "Corticosteroids",
                "day": 3,
                "therapy_index": 10
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 4,
                "therapy_index": 8
            },
            {
                "therapy": "Tracheostomy",
                "day": 18,
                "therapy_index": 1
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 3,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Corticosteroids",
                "day": 1,
                "therapy_index": 10
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 3,
                "therapy_index": 3
            },
            {
                "therapy": "Inhaled nitric oxide",
                "day": 4,
                "therapy_index": 5
            },
            {
                "therapy": "Prone positioning",
                "day": 5,
                "therapy_index": 4
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 13,
                "therapy_index": 8
            },
            {
                "therapy": "Therapeutic heparin",
                "day": 13,
                "therapy_index": 11
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 3,
                "therapy_index": 2
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 3,
                "therapy_index": 8
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 5,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 7,
                "therapy_index": 4
            },
            {
                "therapy": "Corticosteroids",
                "day": 10,
                "therapy_index": 10
            },
            {
                "therapy": "Therapeutic heparin",
                "day": 13,
                "therapy_index": 11
            },
            {
                "therapy": "Anankinra",
                "day": 15,
                "therapy_index": 16
            },
            {
                "therapy": "Tracheostomy",
                "day": 20,
                "therapy_index": 1
            },
            {
                "therapy": "Inhaled prostacyclin",
                "day": 28,
                "therapy_index": 6
            },
            {
                "therapy": "Inhaled nitric oxide",
                "day": 34,
                "therapy_index": 5
            }
        ]
    },
    {
        "age": 1,
        "gender": 2,
        "ARDS": 1,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Inhaled nitric oxide",
                "day": 2,
                "therapy_index": 5
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 3,
                "therapy_index": 8
            }
        ]
    },
    {
        "age": 3,
        "gender": 2,
        "ARDS": 3,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Diuretics",
                "day": 1,
                "therapy_index": 9
            },
            {
                "therapy": "Inhaled prostacyclin",
                "day": 2,
                "therapy_index": 6
            },
            {
                "therapy": "Corticosteroids",
                "day": 3,
                "therapy_index": 10
            },
            {
                "therapy": "Inhaled nitric oxide",
                "day": 9,
                "therapy_index": 5
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Corticosteroids",
                "day": 1,
                "therapy_index": 10
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 2,
                "therapy_index": 3
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 2,
                "therapy_index": 8
            },
            {
                "therapy": "Diuretics",
                "day": 8,
                "therapy_index": 9
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Diuretics",
                "day": 3,
                "therapy_index": 9
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 4,
                "therapy_index": 2
            },
            {
                "therapy": "Prone positioning",
                "day": 6,
                "therapy_index": 4
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 13,
                "therapy_index": 8
            }
        ]
    },
    {
        "age": 3,
        "gender": 1,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 3,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 2,
                "therapy_index": 3
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Prone positioning",
                "day": 4,
                "therapy_index": 4
            },
            {
                "therapy": "Inhaled nitric oxide",
                "day": 6,
                "therapy_index": 5
            }
        ]
    },
    {
        "age": 3,
        "gender": 1,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Tracheostomy",
                "day": 4,
                "therapy_index": 1
            },
            {
                "therapy": "Diuretics",
                "day": 5,
                "therapy_index": 9
            },
            {
                "therapy": "Corticosteroids",
                "day": 11,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 4,
        "gender": 1,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Diuretics",
                "day": 1,
                "therapy_index": 9
            }
        ]
    },
    {
        "age": 3,
        "gender": 1,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Diuretics",
                "day": 3,
                "therapy_index": 9
            },
            {
                "therapy": "Tracheostomy",
                "day": 12,
                "therapy_index": 1
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 29,
                "therapy_index": 3
            },
            {
                "therapy": "Inhaled prostacyclin",
                "day": 29,
                "therapy_index": 6
            },
            {
                "therapy": "Corticosteroids",
                "day": 29,
                "therapy_index": 10
            },
            {
                "therapy": "Therapeutic heparin",
                "day": 29,
                "therapy_index": 11
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 2,
                "therapy_index": 3
            },
            {
                "therapy": "Diuretics",
                "day": 6,
                "therapy_index": 9
            }
        ]
    },
    {
        "age": 6,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Anti-Fungal",
                "day": 2,
                "therapy_index": 18
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 4,
                "therapy_index": 8
            }
        ]
    },
    {
        "age": 1,
        "gender": 2,
        "ARDS": 3,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 3,
                "therapy_index": 2
            },
            {
                "therapy": "Diuretics",
                "day": 3,
                "therapy_index": 9
            },
            {
                "therapy": "Corticosteroids",
                "day": 5,
                "therapy_index": 10
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 6,
                "therapy_index": 3
            },
            {
                "therapy": "Inhaled prostacyclin",
                "day": 6,
                "therapy_index": 6
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 6,
                "therapy_index": 8
            },
            {
                "therapy": "Tracheostomy",
                "day": 28,
                "therapy_index": 1
            },
            {
                "therapy": "Therapeutic heparin",
                "day": 31,
                "therapy_index": 11
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 3,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Bronchoscopy",
                "day": 2,
                "therapy_index": 7
            },
            {
                "therapy": "Diuretics",
                "day": 4,
                "therapy_index": 9
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 3,
                "therapy_index": 8
            },
            {
                "therapy": "Therapeutic heparin",
                "day": 9,
                "therapy_index": 11
            },
            {
                "therapy": "Prone positioning",
                "day": 10,
                "therapy_index": 4
            },
            {
                "therapy": "Hydroxychloroquine",
                "day": 14,
                "therapy_index": 14
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Azithromycin",
                "day": 7,
                "therapy_index": 13
            }
        ]
    },
    {
        "age": 4,
        "gender": 1,
        "ARDS": 3,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 1,
                "therapy_index": 4
            },
            {
                "therapy": "Diuretics",
                "day": 1,
                "therapy_index": 9
            },
            {
                "therapy": "Azithromycin",
                "day": 1,
                "therapy_index": 13
            },
            {
                "therapy": "Inhaled nitric oxide",
                "day": 2,
                "therapy_index": 5
            },
            {
                "therapy": "Hydroxychloroquine",
                "day": 2,
                "therapy_index": 14
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 3,
                "therapy_index": 8
            },
            {
                "therapy": "Bronchoscopy",
                "day": 4,
                "therapy_index": 7
            },
            {
                "therapy": "Corticosteroids",
                "day": 11,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 1,
        "gender": 2,
        "ARDS": 3,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Corticosteroids",
                "day": 1,
                "therapy_index": 10
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            }
        ]
    },
    {
        "age": 2,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 1,
                "therapy_index": 8
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 2,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 2,
                "therapy_index": 3
            },
            {
                "therapy": "Corticosteroids",
                "day": 6,
                "therapy_index": 10
            },
            {
                "therapy": "Prone positioning",
                "day": 10,
                "therapy_index": 4
            }
        ]
    },
    {
        "age": 5,
        "gender": 1,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Bronchoscopy",
                "day": 2,
                "therapy_index": 7
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Corticosteroids",
                "day": 8,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 4,
        "gender": 1,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Corticosteroids",
                "day": 5,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 4,
        "gender": 1,
        "ARDS": 3,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Prone positioning",
                "day": 20,
                "therapy_index": 4
            },
            {
                "therapy": "Corticosteroids",
                "day": 20,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 2,
                "therapy_index": 2
            },
            {
                "therapy": "Azithromycin",
                "day": 2,
                "therapy_index": 13
            },
            {
                "therapy": "Hydroxychloroquine",
                "day": 2,
                "therapy_index": 14
            },
            {
                "therapy": "Inhaled nitric oxide",
                "day": 3,
                "therapy_index": 5
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 3,
                "therapy_index": 8
            },
            {
                "therapy": "Diuretics",
                "day": 3,
                "therapy_index": 9
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 4,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 4,
                "therapy_index": 4
            },
            {
                "therapy": "Corticosteroids",
                "day": 8,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 1,
                "therapy_index": 8
            },
            {
                "therapy": "Corticosteroids",
                "day": 3,
                "therapy_index": 10
            },
            {
                "therapy": "Azithromycin",
                "day": 3,
                "therapy_index": 13
            },
            {
                "therapy": "Hydroxychloroquine",
                "day": 3,
                "therapy_index": 14
            },
            {
                "therapy": "Diuretics",
                "day": 8,
                "therapy_index": 9
            },
            {
                "therapy": "Tracheostomy",
                "day": 19,
                "therapy_index": 1
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 3,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Diuretics",
                "day": 3,
                "therapy_index": 9
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 4,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 4,
                "therapy_index": 4
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 4,
                "therapy_index": 17
            },
            {
                "therapy": "Anti-Fungal",
                "day": 10,
                "therapy_index": 18
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Diuretics",
                "day": 1,
                "therapy_index": 9
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 2,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 4,
                "therapy_index": 4
            },
            {
                "therapy": "Corticosteroids",
                "day": 8,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 3,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 3,
                "therapy_index": 3
            },
            {
                "therapy": "Corticosteroids",
                "day": 4,
                "therapy_index": 10
            },
            {
                "therapy": "Diuretics",
                "day": 5,
                "therapy_index": 9
            },
            {
                "therapy": "Prone positioning",
                "day": 6,
                "therapy_index": 4
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 7,
                "therapy_index": 8
            },
            {
                "therapy": "Therapeutic heparin",
                "day": 9,
                "therapy_index": 11
            },
            {
                "therapy": "Tracheostomy",
                "day": 13,
                "therapy_index": 1
            }
        ]
    },
    {
        "age": 5,
        "gender": 1,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Corticosteroids",
                "day": 1,
                "therapy_index": 10
            },
            {
                "therapy": "Prone positioning",
                "day": 2,
                "therapy_index": 4
            },
            {
                "therapy": "Diuretics",
                "day": 4,
                "therapy_index": 9
            }
        ]
    },
    {
        "age": 2,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            }
        ]
    },
    {
        "age": 3,
        "gender": 2,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 8,
                "therapy_index": 8
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Prone positioning",
                "day": 9,
                "therapy_index": 4
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 10,
                "therapy_index": 3
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 10,
                "therapy_index": 8
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Remdesivir",
                "day": 4,
                "therapy_index": 12
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 6,
                "therapy_index": 3
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 1,
                "therapy_index": 4
            },
            {
                "therapy": "Diuretics",
                "day": 3,
                "therapy_index": 9
            },
            {
                "therapy": "Corticosteroids",
                "day": 13,
                "therapy_index": 10
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 20,
                "therapy_index": 8
            }
        ]
    },
    {
        "age": 3,
        "gender": 2,
        "ARDS": 3,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Inhaled prostacyclin",
                "day": 1,
                "therapy_index": 6
            },
            {
                "therapy": "Diuretics",
                "day": 1,
                "therapy_index": 9
            },
            {
                "therapy": "Corticosteroids",
                "day": 1,
                "therapy_index": 10
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 2,
                "therapy_index": 2
            },
            {
                "therapy": "Tracheostomy",
                "day": 9,
                "therapy_index": 1
            }
        ]
    },
    {
        "age": 2,
        "gender": 1,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 6,
                "therapy_index": 2
            },
            {
                "therapy": "Corticosteroids",
                "day": 7,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 5,
        "gender": 1,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            }
        ]
    },
    {
        "age": 4,
        "gender": 1,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Prone positioning",
                "day": 1,
                "therapy_index": 4
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Diuretics",
                "day": 5,
                "therapy_index": 9
            },
            {
                "therapy": "Tracheostomy",
                "day": 6,
                "therapy_index": 1
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 3,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 3,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 2,
                "therapy_index": 3
            },
            {
                "therapy": "Inhaled prostacyclin",
                "day": 2,
                "therapy_index": 6
            },
            {
                "therapy": "Corticosteroids",
                "day": 2,
                "therapy_index": 10
            },
            {
                "therapy": "Remdesivir",
                "day": 4,
                "therapy_index": 12
            },
            {
                "therapy": "Diuretics",
                "day": 5,
                "therapy_index": 9
            }
        ]
    },
    {
        "age": 3,
        "gender": 2,
        "ARDS": 3,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Tocilizumab",
                "day": 1,
                "therapy_index": 15
            }
        ]
    },
    {
        "age": 5,
        "gender": 1,
        "ARDS": 3,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 2,
                "therapy_index": 3
            },
            {
                "therapy": "Diuretics",
                "day": 4,
                "therapy_index": 9
            }
        ]
    },
    {
        "age": 6,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 1,
                "therapy_index": 4
            },
            {
                "therapy": "Diuretics",
                "day": 1,
                "therapy_index": 9
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            }
        ]
    },
    {
        "age": 3,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 2,
                "therapy_index": 17
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 4,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 7,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 7,
                "therapy_index": 4
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 7,
                "therapy_index": 8
            },
            {
                "therapy": "Inhaled prostacyclin",
                "day": 16,
                "therapy_index": 6
            },
            {
                "therapy": "Anti-Fungal",
                "day": 16,
                "therapy_index": 18
            },
            {
                "therapy": "Tracheostomy",
                "day": 17,
                "therapy_index": 1
            },
            {
                "therapy": "Corticosteroids",
                "day": 17,
                "therapy_index": 10
            },
            {
                "therapy": "Tocilizumab",
                "day": 24,
                "therapy_index": 15
            }
        ]
    },
    {
        "age": 3,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Diuretics",
                "day": 3,
                "therapy_index": 9
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 4,
                "therapy_index": 8
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 8,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 8,
                "therapy_index": 3
            },
            {
                "therapy": "Corticosteroids",
                "day": 12,
                "therapy_index": 10
            },
            {
                "therapy": "Bronchoscopy",
                "day": 14,
                "therapy_index": 7
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 2,
                "therapy_index": 17
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 6,
                "therapy_index": 3
            },
            {
                "therapy": "Tracheostomy",
                "day": 8,
                "therapy_index": 1
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 2,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 2,
                "therapy_index": 4
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Hydroxychloroquine",
                "day": 2,
                "therapy_index": 14
            },
            {
                "therapy": "Azithromycin",
                "day": 3,
                "therapy_index": 13
            }
        ]
    },
    {
        "age": 1,
        "gender": 2,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            }
        ]
    },
    {
        "age": 6,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Azithromycin",
                "day": 1,
                "therapy_index": 13
            },
            {
                "therapy": "Hydroxychloroquine",
                "day": 1,
                "therapy_index": 14
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 3,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Diuretics",
                "day": 1,
                "therapy_index": 9
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 3,
                "therapy_index": 3
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 3,
                "therapy_index": 8
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 1,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 2,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 2,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 2,
                "therapy_index": 4
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 2,
                "therapy_index": 17
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 1,
                "therapy_index": 4
            },
            {
                "therapy": "Hydroxychloroquine",
                "day": 1,
                "therapy_index": 14
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Azithromycin",
                "day": 2,
                "therapy_index": 13
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 5,
                "therapy_index": 8
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            }
        ]
    },
    {
        "age": 6,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 2,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 2,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 2,
                "therapy_index": 4
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 3,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 5,
                "therapy_index": 2
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 3,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Prone positioning",
                "day": 1,
                "therapy_index": 4
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 2,
                "therapy_index": 3
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 4,
                "therapy_index": 2
            },
            {
                "therapy": "Diuretics",
                "day": 4,
                "therapy_index": 9
            },
            {
                "therapy": "Tocilizumab",
                "day": 13,
                "therapy_index": 15
            },
            {
                "therapy": "Corticosteroids",
                "day": 18,
                "therapy_index": 10
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 21,
                "therapy_index": 8
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 2,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 2,
                "therapy_index": 4
            },
            {
                "therapy": "Azithromycin",
                "day": 2,
                "therapy_index": 13
            },
            {
                "therapy": "Hydroxychloroquine",
                "day": 2,
                "therapy_index": 14
            },
            {
                "therapy": "Diuretics",
                "day": 7,
                "therapy_index": 9
            },
            {
                "therapy": "Bronchoscopy",
                "day": 9,
                "therapy_index": 7
            },
            {
                "therapy": "Tracheostomy",
                "day": 11,
                "therapy_index": 1
            }
        ]
    },
    {
        "age": 5,
        "gender": 1,
        "ARDS": 3,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 1,
                "therapy_index": 4
            },
            {
                "therapy": "Corticosteroids",
                "day": 1,
                "therapy_index": 10
            },
            {
                "therapy": "Diuretics",
                "day": 15,
                "therapy_index": 9
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 1,
                "therapy_index": 4
            },
            {
                "therapy": "Azithromycin",
                "day": 1,
                "therapy_index": 13
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Hydroxychloroquine",
                "day": 3,
                "therapy_index": 14
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 2,
                "therapy_index": 3
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 3,
                "therapy_index": 2
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 6,
                "therapy_index": 8
            },
            {
                "therapy": "Corticosteroids",
                "day": 7,
                "therapy_index": 10
            },
            {
                "therapy": "Tracheostomy",
                "day": 16,
                "therapy_index": 1
            },
            {
                "therapy": "Bronchoscopy",
                "day": 23,
                "therapy_index": 7
            }
        ]
    },
    {
        "age": 6,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Azithromycin",
                "day": 2,
                "therapy_index": 13
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 1,
                "therapy_index": 8
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Corticosteroids",
                "day": 9,
                "therapy_index": 10
            },
            {
                "therapy": "Tracheostomy",
                "day": 14,
                "therapy_index": 1
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Diuretics",
                "day": 1,
                "therapy_index": 9
            },
            {
                "therapy": "Azithromycin",
                "day": 1,
                "therapy_index": 13
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 2,
                "therapy_index": 2
            },
            {
                "therapy": "Corticosteroids",
                "day": 2,
                "therapy_index": 10
            },
            {
                "therapy": "Bronchoscopy",
                "day": 3,
                "therapy_index": 7
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 6,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 6,
                "therapy_index": 4
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Prone positioning",
                "day": 1,
                "therapy_index": 4
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 2,
                "therapy_index": 3
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 2,
                "therapy_index": 8
            },
            {
                "therapy": "Inhaled nitric oxide",
                "day": 3,
                "therapy_index": 5
            },
            {
                "therapy": "Therapeutic heparin",
                "day": 4,
                "therapy_index": 11
            }
        ]
    },
    {
        "age": 2,
        "gender": 1,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Diuretics",
                "day": 1,
                "therapy_index": 9
            }
        ]
    },
    {
        "age": 2,
        "gender": 2,
        "ARDS": 3,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Azithromycin",
                "day": 1,
                "therapy_index": 13
            },
            {
                "therapy": "Hydroxychloroquine",
                "day": 1,
                "therapy_index": 14
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 2,
                "therapy_index": 2
            },
            {
                "therapy": "Prone positioning",
                "day": 2,
                "therapy_index": 4
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            }
        ]
    },
    {
        "age": 4,
        "gender": 1,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Tracheostomy",
                "day": 5,
                "therapy_index": 1
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Prone positioning",
                "day": 1,
                "therapy_index": 4
            },
            {
                "therapy": "Azithromycin",
                "day": 1,
                "therapy_index": 13
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 2,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 2,
                "therapy_index": 3
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Hydroxychloroquine",
                "day": 2,
                "therapy_index": 14
            },
            {
                "therapy": "Corticosteroids",
                "day": 28,
                "therapy_index": 10
            },
            {
                "therapy": "Tracheostomy",
                "day": 29,
                "therapy_index": 1
            }
        ]
    },
    {
        "age": 6,
        "gender": 2,
        "ARDS": 3,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 3,
                "therapy_index": 3
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 7,
                "therapy_index": 8
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 3,
                "therapy_index": 2
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 3,
                "therapy_index": 8
            }
        ]
    },
    {
        "age": 1,
        "gender": 2,
        "ARDS": 3,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 1,
                "therapy_index": 4
            },
            {
                "therapy": "Diuretics",
                "day": 1,
                "therapy_index": 9
            },
            {
                "therapy": "Azithromycin",
                "day": 1,
                "therapy_index": 13
            },
            {
                "therapy": "Hydroxychloroquine",
                "day": 1,
                "therapy_index": 14
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 3,
                "therapy_index": 8
            },
            {
                "therapy": "Corticosteroids",
                "day": 3,
                "therapy_index": 10
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 5,
                "therapy_index": 2
            },
            {
                "therapy": "Tracheostomy",
                "day": 18,
                "therapy_index": 1
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 1,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 1,
                "therapy_index": 4
            },
            {
                "therapy": "Diuretics",
                "day": 1,
                "therapy_index": 9
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 4,
                "therapy_index": 8
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 1,
                "therapy_index": 4
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 1,
                "therapy_index": 8
            },
            {
                "therapy": "Corticosteroids",
                "day": 1,
                "therapy_index": 10
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            }
        ]
    },
    {
        "age": 6,
        "gender": 1,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 2,
                "therapy_index": 17
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 3,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 3,
                "therapy_index": 4
            }
        ]
    },
    {
        "age": 5,
        "gender": 1,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            }
        ]
    },
    {
        "age": 6,
        "gender": 2,
        "ARDS": 1,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Azithromycin",
                "day": 2,
                "therapy_index": 13
            },
            {
                "therapy": "Hydroxychloroquine",
                "day": 2,
                "therapy_index": 14
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 4,
                "therapy_index": 2
            },
            {
                "therapy": "Prone positioning",
                "day": 5,
                "therapy_index": 4
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 6,
                "therapy_index": 3
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 8,
                "therapy_index": 8
            },
            {
                "therapy": "Corticosteroids",
                "day": 11,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            }
        ]
    },
    {
        "age": 5,
        "gender": 2,
        "ARDS": 3,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 2,
                "therapy_index": 3
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 3,
                "therapy_index": 2
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 3,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Azithromycin",
                "day": 2,
                "therapy_index": 13
            },
            {
                "therapy": "Hydroxychloroquine",
                "day": 2,
                "therapy_index": 14
            },
            {
                "therapy": "Diuretics",
                "day": 3,
                "therapy_index": 9
            }
        ]
    },
    {
        "age": 4,
        "gender": 1,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 4,
                "therapy_index": 17
            },
            {
                "therapy": "Anti-Fungal",
                "day": 5,
                "therapy_index": 18
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 6,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 7,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 7,
                "therapy_index": 4
            }
        ]
    },
    {
        "age": 3,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 2,
                "therapy_index": 3
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 3,
                "therapy_index": 2
            },
            {
                "therapy": "Prone positioning",
                "day": 3,
                "therapy_index": 4
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 5,
                "therapy_index": 17
            },
            {
                "therapy": "Tracheostomy",
                "day": 8,
                "therapy_index": 1
            }
        ]
    },
    {
        "age": 6,
        "gender": 2,
        "ARDS": 1,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Azithromycin",
                "day": 2,
                "therapy_index": 13
            },
            {
                "therapy": "Hydroxychloroquine",
                "day": 2,
                "therapy_index": 14
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 5,
                "therapy_index": 8
            },
            {
                "therapy": "Corticosteroids",
                "day": 5,
                "therapy_index": 10
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 15,
                "therapy_index": 3
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 3,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Prone positioning",
                "day": 1,
                "therapy_index": 4
            },
            {
                "therapy": "Diuretics",
                "day": 3,
                "therapy_index": 9
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 6,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 15,
                "therapy_index": 3
            },
            {
                "therapy": "Bronchoscopy",
                "day": 15,
                "therapy_index": 7
            }
        ]
    },
    {
        "age": 2,
        "gender": 2,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            }
        ]
    },
    {
        "age": 2,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Diuretics",
                "day": 5,
                "therapy_index": 9
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 2,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 3,
                "therapy_index": 4
            },
            {
                "therapy": "Diuretics",
                "day": 4,
                "therapy_index": 9
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 5,
                "therapy_index": 2
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 3,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Corticosteroids",
                "day": 1,
                "therapy_index": 10
            },
            {
                "therapy": "Remdesivir",
                "day": 1,
                "therapy_index": 12
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Renal replacement therapy",
                "day": 3,
                "therapy_index": 8
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 3,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Corticosteroids",
                "day": 1,
                "therapy_index": 10
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 5,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 5,
                "therapy_index": 3
            },
            {
                "therapy": "Prone positioning",
                "day": 6,
                "therapy_index": 4
            },
            {
                "therapy": "Diuretics",
                "day": 6,
                "therapy_index": 9
            }
        ]
    },
    {
        "age": 5,
        "gender": 1,
        "ARDS": 1,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Remdesivir",
                "day": 1,
                "therapy_index": 12
            },
            {
                "therapy": "Diuretics",
                "day": 4,
                "therapy_index": 9
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 2,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 1,
                "therapy_index": 2
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Anti-Bacterial",
                "day": 1,
                "therapy_index": 17
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            }
        ]
    },
    {
        "age": 3,
        "gender": 1,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 1,
                "therapy_index": 3
            },
            {
                "therapy": "Diuretics",
                "day": 2,
                "therapy_index": 9
            },
            {
                "therapy": "Corticosteroids",
                "day": 7,
                "therapy_index": 10
            }
        ]
    },
    {
        "age": 4,
        "gender": 2,
        "ARDS": 2,
        "outcome": "died",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            },
            {
                "therapy": "Neuro-muscular blockade",
                "day": 2,
                "therapy_index": 3
            },
            {
                "therapy": "PEEP>10 cmH_2O",
                "day": 3,
                "therapy_index": 2
            },
            {
                "therapy": "Diuretics",
                "day": 3,
                "therapy_index": 9
            }
        ]
    },
    {
        "age": 6,
        "gender": 2,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            }
        ]
    },
    {
        "age": 3,
        "gender": 2,
        "ARDS": 1,
        "outcome": "discharged",
        "therapies": [
            {
                "therapy": "Intubated",
                "day": 1,
                "therapy_index": 0
            }
        ]
    }
]