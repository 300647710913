import React, { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import Tooltip from 'rc-tooltip';
import logo from "./logo.png";
import { data } from "./data";

const App = () => {

  const [gender, setGender] = useState(null);
  const [age, setAge] = useState(null);
  const [ards, setArds] = useState(null);
  const [outcome, setOutcome] = useState(null);

  const [trach, setTrach] = useState(false);
  const [peep, setPeep] = useState(false);
  const [nmb, setNmb] = useState(true);
  const [prone, setProne] = useState(true);
  const [nitric, setNitric] = useState(false);
  const [prostacyclin, setProstacyclin] = useState(false);
  const [broncho, setBroncho] = useState(false);
  const [rrt, setRrt] = useState(false);
  const [diuretics, setDiuretics] = useState(false);
  const [cortico, setCortico] = useState(false);
  const [heparin, setHeparin] = useState(false);
  const [remdesivir, setRemdesivir] = useState(false);
  const [azithromycin, setAzithromycin] = useState(false);
  const [hydro, setHydro] = useState(false);
  const [tocilizumab, setTocilizumab] = useState(false);
  const [anankinra, setAnankinra] = useState(false);
  const [antibac, setAntibac] = useState(false);
  const [antifung, setAntifung] = useState(false);
  const [hoveredTherapy, setHoveredTherapy] = useState(null);
  console.log(hoveredTherapy)
  const therapies = [ 
      "Intubated",
      "Tracheostomy",
      "PEEP>10 cmH_2O",
      "Neuro-muscular blockade",
      "Prone positioning",
      "Inhaled nitric oxide",
      "Inhaled prostacyclin",
      "Bronchoscopy",
      "Renal replacement therapy",
      "Diuretics",
      "Corticosteroids",
      "Therapeutic heparin",
      "Remdesivir",
      "Azithromycin",
      "Hydroxychloroquine",
      "Tocilizumab",
      "Anankinra",
      "Anti-Bacterial",
      "Anti-Fungal",
      "discharged",
      "died"
    ];

  let usedTherapies = [...therapies];
  // console.log('usedTherapies: ', usedTherapies)
  if (!trach) {
    usedTherapies = usedTherapies.filter(x => x!=="Tracheostomy");
  }
  if (!peep) {
    usedTherapies = usedTherapies.filter(x => x!=="PEEP>10 cmH_2O");
  }
  if (!nmb) {
    usedTherapies = usedTherapies.filter(x => x!=="Neuro-muscular blockade");
  }
  if (!prone) {
    usedTherapies = usedTherapies.filter(x => x!=="Prone positioning");
  }
  if (!nitric) {
    usedTherapies = usedTherapies.filter(x => x!=="Inhaled nitric oxide");
  }
  if (!prostacyclin) {
    usedTherapies = usedTherapies.filter(x => x!=="Inhaled prostacyclin");
  }
  if (!broncho) {
    usedTherapies = usedTherapies.filter(x => x!=="Bronchoscopy");
  }
  if (!rrt) {
    usedTherapies = usedTherapies.filter(x => x!=="Renal replacement therapy");
  }
  if (!diuretics) {
    usedTherapies = usedTherapies.filter(x => x!=="Diuretics");
  }
  if (!cortico) {
    usedTherapies = usedTherapies.filter(x => x!=="Corticosteroids");
  }
  if (!heparin) {
    usedTherapies = usedTherapies.filter(x => x!=="Therapeutic heparin");
  }
  if (!remdesivir) {
    usedTherapies = usedTherapies.filter(x => x!=="Remdesivir");
  }
  if (!azithromycin) {
    usedTherapies = usedTherapies.filter(x => x!=="Azithromycin");
  }
  if (!hydro) {
    usedTherapies = usedTherapies.filter(x => x!=="Hydroxychloroquine");
  }
  if (!tocilizumab) {
    usedTherapies = usedTherapies.filter(x => x!=="Tocilizumab");
  }
  if (!anankinra) {
    usedTherapies = usedTherapies.filter(x => x!=="Anankinra");
  }
  if (!antibac) {
    usedTherapies = usedTherapies.filter(x => x!=="Anti-Bacterial");
  }
  if (!antifung) {
    usedTherapies = usedTherapies.filter(x => x!=="Anti-Fungal");
  }
 if (usedTherapies.length === 3) {
    usedTherapies = [...therapies];
 }

  useEffect(() => {
    ReactTooltip.rebuild()
  }, []);

  let actualData = gender ? data.filter(p => p.gender === gender) : data;
  actualData = age ? actualData.filter(p => p.age === age) : actualData;
  actualData = ards ? actualData.filter(p => p.ARDS === ards) : actualData;
  actualData = outcome ? actualData.filter(p => p.outcome === outcome) : actualData;

  const paths = actualData.map(patient => patient.therapies.map(t => t.therapy).concat([patient.outcome]))
                          .map(path => path.filter(t => usedTherapies.includes(t)))
  console.log(paths.length)

  const connections = []; // This contains the final data for the Sankey
  for (let path of paths) {
    for (let x = 0; x < path.length - 1; x++) {
      connections.push([path[x], path[x + 1]]) //produce connection pairs
    }
  }
  

  const sankey = usedTherapies.map((therapy, index) => {
    let outTop = 0; let inTop = 0;
    return {
      name: therapy,
      index: index,
      height: paths.filter(path => path.includes(therapy)).length,
      out: usedTherapies.filter(t => t !== therapy).map(nextTherapy => {
        const count = connections.filter(conn => conn[0] === therapy && conn[1] === nextTherapy).length;
        outTop += count;
        return {
          name: nextTherapy,
          count: count,
          index: usedTherapies.indexOf(nextTherapy),
          top: outTop - count
        }
      }),
      in: usedTherapies.filter(t => t !== therapy).map(prevTherapy => {
        const count = connections.filter(conn => conn[0] === prevTherapy && conn[1] === therapy).length;
        inTop += count;
        return {
          name: prevTherapy,
          count: count,
          index: usedTherapies.indexOf(prevTherapy),
          top: inTop - count
        }
      })
    }
  })
  console.log(sankey)

  const BAR_WIDTH = 30;
  const BAR_SPACING = 900/usedTherapies.length;
  const dischargeHeight = sankey.filter(t => t.name === "discharged")[0].height;

  return (
    <div className="m-4">
      <div className="-ml-8 flex flex-row justify-center flex-wrap items-center">

      <a href="https://www.imperial.ac.uk/artificial-intelligence">
        <img className="logo" src={logo} />
      </a>
      <h1 className=" text-2xl font-bold">Covid ICU AI Data Explorer</h1>
      </div>
      <div className="flex flex-col justify-center items-center">
        <h2 className="text-gray-500 text-sm text-center my-4 max-w-xl">
          This is a web tool to explore the data used in the paper: <br /> <a href="https://link.springer.com/article/10.1007/s00134-021-06389-z" className="text-blue-700 font-semibold">Natural history, trajectory, and management of mechanically ventilated COVID-19 patients in the United Kingdom</a> (<a href="https://link.springer.com/content/pdf/10.1007/s00134-021-06389-z.pdf" className="font-semibold text-gray-500">PDF</a>)</h2>
        <h3 className="text-gray-500 text-xs text-center my-4 max-w-xl mx-4">
        Brijesh V. Patel, Shlomi Haar, Rhodri Handslip, Chaiyawan Auepanwiriyakul, Teresa Mei-Ling Lee, Sunil Patel, J. Alex Harston, Feargus Hosking-Jervis, Donna Kelly, Barnaby Sanderson, Barbara Borgatta, Kate Tatham, Ingeborg Welters, Luigi Camporota, Anthony C. Gordon, Matthieu Komorowski, David Antcliffe, John R. Prowle, Zudin Puthucheary & Aldo A. Faisal
        </h3>
        <h3 className="text-gray-500 text-xs text-center mb-4 max-w-lg mx-4">(Corresponding author: <a className="text-blue-700 font-semibold" href="mailto:a.faisal@imperial.ac.uk">Prof. Aldo Faisal</a>)</h3>
      </div>
      <div className="flex justify-center flex-row flex-wrap">
        <div className="flex flex-row flex-wrap justify-center">

          <select
            className="border-3 bg-gray-100 m-4 p-4 rounded-md max-h-14"
            onChange={(e) => setGender(parseInt(e.target.value))}
            >
            <option value={0}>All Genders</option>
            <option value={1}>Female</option>
            <option value={2}>Male</option>
          </select>

          <select
            className="p-4 m-4 bg-gray-100 border-3 rounded-md max-h-14"
            onChange={(e) => setAge(parseInt(e.target.value))}
            >
            <option value={0}>All Ages</option>
            <option value={1}>20-30</option>
            <option value={2}>30-40</option>
            <option value={3}>40-50</option>
            <option value={4}>50-60</option>
            <option value={5}>60-70</option>
            <option value={6}>70-80</option>
          </select>

        <div className="flex flex-row justify-center">
          <select
            className="p-4 m-4 bg-gray-100 border-3 rounded-md max-h-14"
            onChange={(e) => setArds(parseInt(e.target.value))}
            >
            <option value={0}>All ARDS Severities</option>
            <option value={1}>Mild ARDS</option>
            <option value={2}>Moderate ARDS</option>
            <option value={3}>Severe ARDS</option>
          </select>

          <select
            className="p-4 m-4 bg-gray-100 border-3 rounded-md max-h-14"
            onChange={(e) =>
              setOutcome(
                e.target.value.toString() === "0" ? null : e.target.value
                )
              }
              >
            <option value={0}>All Outcomes</option>
            <option value={"died"}>Died</option>
            <option value={"discharged"}>Discharged</option>
          </select>
        </div>
      </div>
    </div>

      <div className="grid grid-cols-2 sm:grid-rows-6 sm:grid-flow-col p-0 sm:p-8 justify-items-center md:justify-items-start m-auto max-w-4xl">
        <div className="my-1 sm:my-2">
          <label className="mr-0.5 sm:mr-4 text-gray-700 text-xs sm:text-md md:text-lg lg:text-xl">
            Tracheostomy
          </label>
          <input
            className="p-4 checked:bg-blue-600 checked:border-transparent"
            type="checkbox"
            checked={trach}
            onChange={(e) => setTrach(e.target.checked)}
            />
        </div>
        <div className="my-1 sm:my-2">
          <label className="mr-0.5 sm:mr-4 text-gray-700 text-xs sm:text-md md:text-lg lg:text-xl">
            {"PEEP>10 cmH_2O"}
          </label>
          <input
            className="p-4 checked:bg-blue-600 checked:border-transparent"
            type="checkbox"
            checked={peep}
            onChange={(e) => setPeep(e.target.checked)}
            />
        </div>
        <div className="my-1 sm:my-2">
          <label className="mr-0.5 sm:mr-4 text-gray-700 text-xs sm:text-md md:text-lg lg:text-xl">
            Neuro-muscular blockade
          </label>
          <input
            className="p-4 checked:bg-blue-600 checked:border-transparent"
            type="checkbox"
            checked={nmb}
            onChange={(e) => setNmb(e.target.checked)}
          />
        </div>
        <div className="my-1 sm:my-2">
          <label className="mr-0.5 sm:mr-4 text-gray-700 text-xs sm:text-md md:text-lg lg:text-xl">
            Prone positioning
          </label>
          <input
            className="p-4 checked:bg-blue-600 checked:border-transparent"
            type="checkbox"
            checked={prone}
            onChange={(e) => setProne(e.target.checked)}
          />
        </div>
        <div className="my-1 sm:my-2">
          <label className="mr-0.5 sm:mr-4 text-gray-700 text-xs sm:text-md md:text-lg lg:text-xl">
            Inhaled nitric oxide
          </label>
          <input
            className="p-4 checked:bg-blue-600 checked:border-transparent"
            type="checkbox"
            checked={nitric}
            onChange={(e) => setNitric(e.target.checked)}
          />
        </div>
        <div className="my-1 sm:my-2">
          <label className="mr-0.5 sm:mr-4 text-gray-700 text-xs sm:text-md md:text-lg lg:text-xl">
            Inhaled prostacyclin
          </label>
          <input
            className="p-4 checked:bg-blue-600 checked:border-transparent"
            type="checkbox"
            checked={prostacyclin}
            onChange={(e) => setProstacyclin(e.target.checked)}
          />
        </div>
        <div className="my-1 sm:my-2">
          <label className="mr-0.5 sm:mr-4 text-gray-700 text-xs sm:text-md md:text-lg lg:text-xl">
            Bronchoscopy
          </label>
          <input
            className="p-4 checked:bg-blue-600 checked:border-transparent"
            type="checkbox"
            checked={broncho}
            onChange={(e) => setBroncho(e.target.checked)}
          />
        </div>
        <div className="my-1 sm:my-2">
          <label className="mr-0.5 sm:mr-4 text-gray-700 text-xs sm:text-md md:text-lg lg:text-xl">
            Renal replacement therapy
          </label>
          <input
            type="checkbox"
            checked={rrt}
            onChange={(e) => setRrt(e.target.checked)}
          />
        </div>
        <div className="my-1 sm:my-2">
          <label className="mr-0.5 sm:mr-4 text-gray-700 text-xs sm:text-md md:text-lg lg:text-xl">
            Diuretics
          </label>
          <input
            type="checkbox"
            checked={diuretics}
            onChange={(e) => setDiuretics(e.target.checked)}
          />
        </div>
        <div className="my-1 sm:my-2">
          <label className="mr-0.5 sm:mr-4 text-gray-700 text-xs sm:text-md md:text-lg lg:text-xl">
            Corticosteroids
          </label>
          <input
            type="checkbox"
            checked={cortico}
            onChange={(e) => setCortico(e.target.checked)}
          />
        </div>
        <div className="my-1 sm:my-2">
          <label className="mr-0.5 sm:mr-4 text-gray-700 text-xs sm:text-md md:text-lg lg:text-xl">
            Therapeutic heparin
          </label>
          <input
            type="checkbox"
            checked={heparin}
            onChange={(e) => setHeparin(e.target.checked)}
          />
        </div>
        <div className="my-1 sm:my-2">
          <label className="mr-0.5 sm:mr-4 text-gray-700 text-xs sm:text-md md:text-lg lg:text-xl">
            Remdesivir
          </label>
          <input
            type="checkbox"
            checked={remdesivir}
            onChange={(e) => setRemdesivir(e.target.checked)}
          />
        </div>
        <div className="my-1 sm:my-2">
          <label className="mr-0.5 sm:mr-4 text-gray-700 text-xs sm:text-md md:text-lg lg:text-xl">
            Azithromycin
          </label>
          <input
            type="checkbox"
            checked={azithromycin}
            onChange={(e) => setAzithromycin(e.target.checked)}
          />
        </div>
        <div className="my-1 sm:my-2">
          <label className="mr-0.5 sm:mr-4 text-gray-700 text-xs sm:text-md md:text-lg lg:text-xl">
            Hydroxychloroquine
          </label>
          <input
            type="checkbox"
            checked={hydro}
            onChange={(e) => setHydro(e.target.checked)}
          />
        </div>
        <div className="my-1 sm:my-2">
          <label className="mr-0.5 sm:mr-4 text-gray-700 text-xs sm:text-md md:text-lg lg:text-xl">
            Tocilizumab
          </label>
          <input
            type="checkbox"
            checked={tocilizumab}
            onChange={(e) => setTocilizumab(e.target.checked)}
          />
        </div>
        <div className="my-1 sm:my-2">
          <label className="mr-0.5 sm:mr-4 text-gray-700 text-xs sm:text-md md:text-lg lg:text-xl">
            Anankinra
          </label>
          <input
            type="checkbox"
            checked={anankinra}
            onChange={(e) => setAnankinra(e.target.checked)}
          />
        </div>
        <div className="my-1 sm:my-2">
          <label className="mr-0.5 sm:mr-4 text-gray-700 text-xs sm:text-md md:text-lg lg:text-xl">
            Anti-Bacterial
          </label>
          <input
            type="checkbox"
            checked={antibac}
            onChange={(e) => setAntibac(e.target.checked)}
          />
        </div>
        <div className="my-1 sm:my-2">
          <label className="mr-0.5 sm:mr-4 text-gray-700 text-xs sm:text-md md:text-lg lg:text-xl">
            Anti-Fungal
          </label>
          <input
            type="checkbox"
            checked={antifung}
            onChange={(e) => setAntifung(e.target.checked)}
          />
        </div>
      </div>

      <br />
      {/* <div>{actualData.length} patient{actualData.length===1 ? "" : "s"}</div> */}

      {sankey.map((therapy) => (
        <ReactTooltip id={therapy.name} key={therapy.name}>
          <div>{therapy.name}</div>
          <div>
            {therapy.height} patient{therapy.height === 1 ? "" : "s"}
          </div>
        </ReactTooltip>
      ))}
      {sankey.map((therapy) => {
        return therapy.out.map((nextTherapy) => {
          const id = `path_${therapy.index}_${nextTherapy.index}`;
          return (
            <ReactTooltip key={id} id={id}>
              <span>
                {therapy.name} to{" "}
                {nextTherapy.name === "died" ? "death" : nextTherapy.name} (
                {nextTherapy.count} patient{"s"})
              </span>
            </ReactTooltip>
          );
        });
      })}

      <div className="svg-container flex justify-center">
        <svg
          width={
            usedTherapies.length * BAR_WIDTH + (usedTherapies.length - 1) * BAR_SPACING
          }
          height="400px"
          preserveAspectRatio="none"
          viewBox={`0 0 ${
            usedTherapies.length * BAR_WIDTH + (usedTherapies.length - 1) * BAR_SPACING
          } ${actualData.length}`}
        >
          {sankey.map((therapy, index) => {
            const rectX = index * (BAR_SPACING + BAR_WIDTH);
            return (
              <React.Fragment key={index}>
                {therapy.out.map((nextTherapy) => {
                  const startX =
                    nextTherapy.index > therapy.index
                      ? rectX + BAR_WIDTH
                      : rectX;
                  const startTopY = nextTherapy.top;
                  const startBottomY = nextTherapy.top + nextTherapy.count;
                  const destination = sankey
                    .filter((t) => t.name === nextTherapy.name)[0]
                    .in.filter((t) => t.name === therapy.name)[0];
                  const isDeath = nextTherapy.name === "died";
                  let endX =
                    nextTherapy.index < therapy.index
                      ? nextTherapy.index * (BAR_SPACING + BAR_WIDTH) +
                        BAR_WIDTH
                      : nextTherapy.index * (BAR_SPACING + BAR_WIDTH);
                  if (isDeath) endX -= BAR_SPACING + BAR_WIDTH;
                  const endTopY =
                    destination.top + (isDeath ? dischargeHeight : 0);
                  const endBottomY =
                    destination.top +
                    destination.count +
                    (isDeath ? dischargeHeight : 0);
                  const points = [
                    `${startX},${startTopY}`,
                    `${startX},${startBottomY}`,
                    `${endX},${endBottomY}`,
                    `${endX},${endTopY}`,
                  ];
                  const isPrev = hoveredTherapy === nextTherapy.name;
                  const isNext = hoveredTherapy === destination.name;
                  return (
                    <polygon
                      key={`path_${therapy.index}_${nextTherapy.index}`}
                      points={points.join(" ")}
                      fill={isPrev ? "#3498db" : isNext ? "#e27f45" : "#888888"}
                      className={`arrow ${isPrev || isNext ? "active" : ""}`}
                      data-tip
                      data-for={`path_${therapy.index}_${nextTherapy.index}`}
                    />
                  );
                })}
              </React.Fragment>
            );
          })}

          {sankey.map((therapy, index) => {
            let rectX = index * (BAR_SPACING + BAR_WIDTH);
            const isDeath = therapy.name === "died";
            if (isDeath) rectX -= BAR_SPACING + BAR_WIDTH;
            return (
              <rect
                key={index}
                x={rectX}
                y={isDeath ? dischargeHeight : 0}
                onMouseEnter={() => setHoveredTherapy(therapy.name)}
                onMouseLeave={() => setHoveredTherapy(null)}
                width={BAR_WIDTH}
                height={therapy.height}
                className="therapy"
                fill={
                  therapy.name === "died"
                  ? "#da5a52"
                  : therapy.name === "discharged"
                  ? "#56b091"
                  : therapy.name === "Intubated"
                  ? "#7570b3"
                  : "#34495e"
                }
                data-tip
                data-for={therapy.name}
              />
            );
          })}
        </svg>
      </div>
      <div className="footer">
        <p>Designed at <a href="https://faisallab.org">Faisal Lab</a></p>
      </div>
    </div>
  );
};

App.propTypes = {
  
};

export default App;
